import z from "zod";

import { messageRequestUnauthorized } from "./auth";
import { type CanvasSpecifier, canvasSpecifier } from "./canvasSpecifier";
import { type Checksum, checksum } from "./checksum";
import {
	type DocumentMetadata,
	type MessageInitMetadataRoomResponseAccepted,
	type MessageInitMetadataRoomResponseRejected,
} from "./documentMetadata";

/**
 * Body of the POST message that initializes the room.
 */
export const messageIdempotentInitRoomRequest = z.object({
	type: z.literal("idempotent-init-room-request"),
	canvasSpecifier,
	pageSlug: z.string(),
});
export type MessageIdempotentInitRoomRequest = z.infer<typeof messageIdempotentInitRoomRequest>;

export function makeMessageIdempotentInitMetadataRoomRequest(
	canvasSpecifier: CanvasSpecifier,
	pageSlug: string
): MessageIdempotentInitRoomRequest {
	return { type: "idempotent-init-room-request", canvasSpecifier, pageSlug };
}

//
// Successful room init request.
//

export function makeMessageIdempotentInitMetadataRoomResponseAccepted(
	version: number,
	canvasMetadata: DocumentMetadata
): MessageInitMetadataRoomResponseAccepted {
	return {
		type: "idempotent-init-metadata-room-response-accepted",
		version,
		canvasMetadata,
	};
}

export function makeMessageIdempotentInitMetadataRoomResponseRejected(
	version: number
): MessageInitMetadataRoomResponseRejected {
	return {
		type: "idempotent-init-metadata-room-response-rejected",
		version,
	};
}

export const messageIdempotentInitRoomResponseAccepted = z.object({
	type: z.literal("idempotent-init-room-response-accepted"),
	version: z.number(),
	page: z.unknown(),
	checksum: checksum.optional(),
});
export type MessageInitRoomResponseAccepted = z.infer<
	typeof messageIdempotentInitRoomResponseAccepted
>;

export function makeMessageIdempotentInitRoomResponseAccepted(
	version: number,
	page: unknown,
	checksum?: Checksum | undefined
): MessageInitRoomResponseAccepted {
	return { type: "idempotent-init-room-response-accepted", version, page, checksum };
}

//
// Failed room init request.
//

export const messageIdempotentInitRoomResponseRejected = z.object({
	type: z.literal("idempotent-init-room-response-rejected"),
	error: z.string(),
	version: z.optional(z.number()),
	canvas: z.optional(z.unknown()),
});
export type MessageIdempotentInitRoomResponseRejected = z.infer<
	typeof messageIdempotentInitRoomResponseRejected
>;

export function makeMessageIdempotentInitRoomResponseRejected(
	error: string,
	version?: number
): MessageIdempotentInitRoomResponseRejected {
	return { type: "idempotent-init-room-response-rejected", error, version };
}

//
// Room init response.
//

/**
 * A response from the server that a transaction has been accepted or rejected.
 */
export const messageIdempotentInitCanvasRoomResponse = z.union([
	messageIdempotentInitRoomResponseAccepted,
	messageIdempotentInitRoomResponseRejected,
	messageRequestUnauthorized,
]);

export type MessageIdempotentInitCanvasRoomResponse = z.infer<
	typeof messageIdempotentInitCanvasRoomResponse
>;
