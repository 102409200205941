import { useMutation } from "@tanstack/react-query";

import { useLogger } from "~/components/canvas/hooks/useLogger";

import { useUpdateWorkspaceMutation } from "../workspaces";

export const useToggleDocumentPinnedMutation = () => {
	const logger = useLogger("useToggleDocumentPinnedMutation");

	const { updateAsync: updateWorkspace } = useUpdateWorkspaceMutation();

	return useMutation({
		mutationFn: async (args: {
			workspaceName: string;
			documentId: string;
			pinned: boolean;
		}) => {
			const { workspaceName, documentId } = args;

			return await updateWorkspace({ workspaceName }, (workspace) => {
				if (!workspace) {
					logger.error("Workspace not found");
					return;
				}

				const document = workspace.documents[documentId];
				if (!document) {
					logger.error("Document not found");
					return;
				}

				// document.pinnedAt = pinned ? new Date() : undefined;
				return workspace;
			});
		},
	});
};
