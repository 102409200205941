import { useCallback, useMemo } from "react";

import { useAuth } from "~/auth/useAuth";
import { useDeleteConfirmModal } from "~/components/common/useDeleteConfirmModal";
import { useGitRepoStatusQuery } from "~/data/git";
import {
	ALL_USERS,
	useCanPublishQuery,
	useIsPublishedQuery,
	useShareMutation,
} from "~/data/permissions";
import { useRevokeShareMutation } from "~/data/permissions/useRevokeShareMutation";
import { useRouteParams } from "~/data/route";
import { LOCAL_WORKSPACE_NAME } from "~/data/workspaces/constants";
import { getDocsWebsite } from "~/utils/docsapi";

export const usePublish = () => {
	const { isAuthenticated } = useAuth();
	const { workspaceName, documentId } = useRouteParams();

	const { data: gitRepoStatus } = useGitRepoStatusQuery({ workspaceName, documentId });
	const isPublishBranch = gitRepoStatus?.isRepo && gitRepoStatus.branch === "main";

	const published = useIsPublishedQuery({ workspaceName, documentId });
	const publishPerms = useCanPublishQuery({ workspaceName, documentId });
	const { mutateAsync: publishDocument, isPending: isPublishing } = useShareMutation();

	const isCloudWorkspace = workspaceName !== LOCAL_WORKSPACE_NAME;

	// repo cannot be shared if:
	// * the user is not authenticated,
	// * the repo is not published
	// * the share permission check failed
	// * they are not allowed to share
	// * a share request is already happening
	const { isShared, shareLink } = useMemo(() => {
		const isShared = published.isSuccess && published.data;

		if (isShared) {
			return { isShared, shareLink: `${getDocsWebsite()}/d/${workspaceName}/${documentId}` };
		} else {
			return { isShared, shareLink: "" };
		}
	}, [published.isSuccess, published.data, workspaceName, documentId]);

	const hasSharePerm = publishPerms.isSuccess && publishPerms.data;
	const canShare =
		!isPublishing && !isShared && isAuthenticated && isCloudWorkspace && hasSharePerm;

	const authorizedToPublish = isAuthenticated && isCloudWorkspace && hasSharePerm;

	const cantShareReason = useMemo(() => {
		if (publishPerms.isSuccess && publishPerms.data) {
			return undefined;
		}

		if (canShare) return "";

		if (!isCloudWorkspace) {
			return "Add to a cloud workspace to publish";
		}

		if (!isAuthenticated) {
			return "Log in to publish";
		}

		if (publishPerms.isSuccess && !publishPerms.data) {
			return "Only workspace owners can publish";
		}

		if (
			published.isPending ||
			(publishPerms.isPending && !published.isError && !publishPerms.isError)
		) {
			return "Checking permissions to publish...";
		}

		return "Something went wrong, please try again";
	}, [
		publishPerms.isSuccess,
		publishPerms.data,
		publishPerms.isPending,
		publishPerms.isError,
		canShare,
		isCloudWorkspace,
		isAuthenticated,
		published.isPending,
		published.isError,
	]);

	const { deleteConfirmation } = useDeleteConfirmModal();

	const { mutateAsync: revokeShare, isPending: isRevoking } = useRevokeShareMutation();

	const handleRevokeShare = useCallback(async () => {
		const confirmed = await deleteConfirmation({
			name: "public share link",
			action: "revoke",
			confirmLabel: "Revoke Share",
		});

		if (confirmed) {
			await revokeShare({ workspaceName, documentId, user: ALL_USERS });
		}
	}, [deleteConfirmation, revokeShare, workspaceName, documentId]);

	const confirmShare = useCallback(async () => {
		await publishDocument({ workspaceName, documentId, user: ALL_USERS });
	}, [publishDocument, workspaceName, documentId]);

	let status:
		| "not-published"
		| "publishing"
		| "publishing-error"
		| "published"
		| "unpublishing"
		| "unpublishing-error"
		| "cannot-publish-no-permissions" = "not-published";

	if (isPublishing || published.isPending) {
		status = "publishing";
	} else if (isRevoking) {
		status = "unpublishing";
	} else if (isShared) {
		status = "published";
	} else if (publishPerms.isError) {
		status = "publishing-error";
	} else if (!hasSharePerm) {
		status = "cannot-publish-no-permissions";
	}

	return useMemo(
		() => ({
			authorizedToPublish,
			isPublishBranch,
			isShared,
			isRevoking,
			canShare,
			cantShareReason,
			shareLink,
			confirmShare,
			handleRevokeShare,
			isCloudWorkspace,
			status,
		}),
		[
			authorizedToPublish,
			isPublishBranch,
			isShared,
			isRevoking,
			canShare,
			cantShareReason,
			shareLink,
			confirmShare,
			handleRevokeShare,
			isCloudWorkspace,
			status,
		]
	);
};
