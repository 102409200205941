import {
	type Attrs,
	type DOMOutputSpec,
	type Node,
	type NodeSpec,
	type ParseRule,
} from "prosemirror-model";

import { CELL_SPEC_ATTRS, DEFAULT_CELL_ID } from "../../prosemirror-utils/constants";
import { makeTextCellDefaultToDOMAttrs } from "../../prosemirror-utils/makeTextCellDefaultToDOMAttrs";

// :: NodeSpec A heading textblock, with a `level` attribute that
// should hold the number 1 to 6. Parsed and serialized as `<h1>` to
// `<h6>` elements.

/** Generates an anchor tag for a heading node. */
export function getHeadingAnchorTag(node: Node) {
	// FIXME: This is NOT globally unique.
	return encodeURIComponent(node.textContent.toLowerCase().replace(/\s+/g, "-"));
}

export const heading: NodeSpec = {
	attrs: {
		...CELL_SPEC_ATTRS,
		level: { default: 1 },
		expandedState: { default: "open" },
	},
	content: "inline*",
	group: "block",
	defining: true,
	parseDOM: Array.from({ length: 6 }).map((_, i) => {
		const level = i + 1;
		const tag = `h${level}`;

		const rule: ParseRule = {
			tag,
			getAttrs(node: string | HTMLElement) {
				if (typeof node === "string") {
					return {};
				}

				const expandedState = node.getAttribute("data-expanded-state") || "open";
				const id = node.getAttribute("id") || DEFAULT_CELL_ID;

				const attrs: Attrs = {
					level,
					expandedState,
					id,
				};

				return attrs;
			},
		};

		return rule;
	}),
	toDOM(node: Node) {
		const level = node.attrs["level"];
		const tag = `h${level}`;

		const attrs: Attrs = {
			...makeTextCellDefaultToDOMAttrs(node),
			// FIXME: This is a placeholder until we generate unique node IDs, somehow. This
			// unfortunately is a hard trade-off between startup perf, what goes in the Markdown
			// doc, something human-readable, and so on.
			"id": getHeadingAnchorTag(node),
			"data-expanded-state": node.attrs["expandedState"],
		};

		const dom: DOMOutputSpec = [tag, attrs, 0];

		return dom;
	},
};
