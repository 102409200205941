import { useMutation } from "@tanstack/react-query";
import { useRouter } from "next/router";

import { useAuth } from "~/auth/useAuth";
import { createRepo } from "~/utils/gitea";

import { giteaQueryKeys } from ".";

export const useCreateRepoMutation = () => {
	const { getAccessTokenSilently } = useAuth();
	const router = useRouter();

	return useMutation({
		mutationKey: giteaQueryKeys.createRepo,
		mutationFn: async ({
			documentId,
			workspaceName,
		}: {
			documentId: string;
			workspaceName: string;
		}) => {
			const token = await getAccessTokenSilently(true, router.asPath);
			if (!token) return;

			return createRepo(token, workspaceName, documentId);
		},
	});
};
