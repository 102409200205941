import type { IdToken } from "@auth0/auth0-spa-js";
import { AssignmentInd } from "@mui/icons-material";
import AccountCircleOutlined from "@mui/icons-material/AccountCircleOutlined";
import Logout from "@mui/icons-material/Logout";
import { useRouter } from "next/router";
import React, { type FC, useEffect, useState } from "react";

import { AppTopBarButton } from "@moment/design-system/AppTopBarButton";
import { Avatar, AvatarSize, AvatarType } from "@moment/design-system/Avatar";
import { DropdownMenu } from "@moment/design-system/DropdownMenu";

import { useAuth } from "~/auth/useAuth";
import { Code } from "~/components/common/Code";

export const BaseProfile: FC = () => {
	const { user, logoutWithRedirect } = useAuth();

	const router = useRouter();

	const { getAccessTokenSilently, getIdTokenClaims } = useAuth();

	const [claims, setClaims] = useState<IdToken | undefined>(undefined);
	useEffect(() => {
		const fetchClaims = async () => {
			const c = await getIdTokenClaims();
			setClaims(c);
		};
		void fetchClaims();
	}, [getIdTokenClaims]);

	const [accessToken, setAccessToken] = useState<string | undefined>(undefined);
	useEffect(() => {
		const fetchAccessToken = async () => {
			const token = await getAccessTokenSilently().catch(() => undefined);
			setAccessToken(token);
		};
		void fetchAccessToken();
	}, [getAccessTokenSilently]);

	return (
		<DropdownMenu.Root>
			<DropdownMenu.Trigger asChild>
				<AppTopBarButton
					data-testid="profile-button"
					variant={"normal"}
					className="group !p-1 hover:!bg-transparent focus:!bg-transparent active:!bg-transparent"
				>
					<Avatar
						imageURL={user?.profilePicture}
						userName={user?.name}
						avatarType={AvatarType.Image}
						size={AvatarSize.sm}
						className="ring-default transition-all group-hover:ring-4 group-focus:ring-4"
					/>
				</AppTopBarButton>
			</DropdownMenu.Trigger>

			<DropdownMenu.Portal>
				<DropdownMenu.Content align="end">
					<DropdownMenu.Item
						onClick={(e) => e.preventDefault()}
						className={"cursor-not-allowed"}
						disabled
					>
						<AccountCircleOutlined />
						<span>{user?.email}</span>
					</DropdownMenu.Item>

					{process.env.APP_ENV !== "production" && (
						<>
							<DropdownMenu.Separator />

							<DropdownMenu.Sub>
								<DropdownMenu.SubTrigger>
									<AssignmentInd />
									<span>User details</span>
								</DropdownMenu.SubTrigger>
								<DropdownMenu.SubContent>
									<Code
										className="mx-3 w-96 overflow-auto py-2 text-xs"
										layout="block"
										content={JSON.stringify(
											{
												accessToken,
												claims,
											},
											null,
											2
										)}
									/>
								</DropdownMenu.SubContent>
							</DropdownMenu.Sub>
						</>
					)}

					<DropdownMenu.Separator />

					<DropdownMenu.Item
						variant="negative"
						onClick={async (e) => {
							e.preventDefault();
							await logoutWithRedirect(router.asPath);
						}}
					>
						<Logout />
						<span>Logout</span>
					</DropdownMenu.Item>
				</DropdownMenu.Content>
			</DropdownMenu.Portal>
		</DropdownMenu.Root>
	);
};

export const Profile = React.memo(BaseProfile);
