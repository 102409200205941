import { isEmpty } from "lodash";

import { isDesktopAppMode } from "~/auth/interop/common";
import { trpc } from "~/utils/trpc";

import { useRouteParams } from "../route";

export const useGitRepoStatusQuery = (args?: { workspaceName: string; documentId: string }) => {
	const routeParams = useRouteParams();

	const { workspaceName = routeParams.workspaceName, documentId = routeParams.documentId } =
		args ?? {};

	const query = trpc.git.status.useQuery(
		{ workspaceName, documentId },
		{
			staleTime: 1000 * 60 * 5, // 5 minutes
			enabled: isDesktopAppMode() && !isEmpty(workspaceName) && !isEmpty(documentId),
		}
	);

	return query;
};
