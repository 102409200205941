import { useMutation } from "@tanstack/react-query";

import type { PageMetadata } from "@moment/api-collab/api-types";
import { generateShortUuid } from "@moment/api-collab/id";

import { createEmptyPage } from "~/components/canvas/easel/editor-state/createEmptyPage";

import { useUpdateDocumentMutation } from "../documents";

export const useCreatePageMutation = ({ workspaceName, documentId }) => {
	const { updateAsync: updateDocument } = useUpdateDocumentMutation({
		workspaceName,
		documentId,
	});

	const mutation = useMutation({
		mutationFn: async (args: {
			workspaceName: string;
			documentId: string;
			pageMetadata: Partial<PageMetadata>;
		}) => {
			const { workspaceName, documentId, pageMetadata } = args;

			const newPageId = pageMetadata.id ?? generateShortUuid();

			const newPage: PageMetadata = {
				id: newPageId,
				parentPageID: null,
				slug: newPageId,
				title: "",
				archived: false,
				...pageMetadata,
			};

			await window.desktopIpcApi?.writeLocalPageContents(
				workspaceName,
				documentId,
				newPageId,
				{
					doc: createEmptyPage().toJSON(),
					metadata: newPage,
				}
			);

			await updateDocument(
				{
					workspaceName,
					documentId,
				},
				(document) => {
					if (!document) return document;

					document.pagesMetadata.push(newPage);
					return document;
				}
			);

			return newPage;
		},
	});
	return mutation;
};
