import { useMutation } from "@tanstack/react-query";

import { trpc } from "~/utils/trpc";

import { useUpdateDocumentMutation } from "../documents";

export const useTogglePageArchivedMutation = ({ workspaceName, documentId }) => {
	const utils = trpc.useUtils();
	const { updateAsync: updateDocument } = useUpdateDocumentMutation({
		workspaceName,
		documentId,
	});

	const mutation = useMutation({
		mutationFn: async ({
			workspaceName,
			documentId,
			pageId,
			// archived,
		}: {
			workspaceName: string;
			documentId: string;
			pageId: string;
			archived?: boolean;
		}) => {
			return updateDocument(
				{
					workspaceName,
					documentId,
				},
				(document) => {
					const page = document?.pagesMetadata.find((page) => page.id === pageId);
					if (!page) return document;

					// page.archived = archived !== undefined ? archived : !page.archived;

					return document;
				}
			);
		},
		onSuccess: () => void utils.git.invalidate(),
	});

	return mutation;
};
