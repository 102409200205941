import { type Attrs, type DOMOutputSpec, type Node, type NodeSpec } from "prosemirror-model";

import { CELL_SPEC_ATTRS } from "../../prosemirror-utils/constants";
import { makeTextCellDefaultToDOMAttrs } from "../../prosemirror-utils/makeTextCellDefaultToDOMAttrs";

// :: NodeSpec A code listing. Disallows marks or non-text inline
// nodes by default. Represented as a `<pre>` element with a
// `<code>` element inside of it.

export const code_block: NodeSpec = {
	content: "text*",
	marks: "",
	group: "block",
	code: true,
	defining: true,
	isolating: true,
	attrs: {
		...CELL_SPEC_ATTRS,
		meta: { default: null },
	},
	parseDOM: [{ tag: "pre", preserveWhitespace: "full" }],
	toDOM(node: Node) {
		const attrs: Attrs = makeTextCellDefaultToDOMAttrs(node, "pre relative");

		const preDOM: DOMOutputSpec = ["p", attrs, ["pre", ["code", 0]]];
		return preDOM;
	},
};
