import { isEmpty } from "lodash";
import { z } from "zod";

import { type CanvasListingItem as DocumentListItemGQL } from "~/api/generated/graphql";

import { DEFAULT_CANVAS_TITLE as DEFAULT_DOCUMENT_TITLE } from "../strings";

export const DocumentListItemSchema = z.object({
	id: z.string(),
	title: z.string(),
	updatedAt: z.coerce.date(),
});

export type DocumentListItem = z.infer<typeof DocumentListItemSchema>;

export const fromGQL = (c: DocumentListItemGQL): DocumentListItem => {
	let title = c.title;
	if (isEmpty(title)) {
		title = DEFAULT_DOCUMENT_TITLE;
	}

	return {
		id: c.id,
		title,
		updatedAt: new Date(c.updatedAt),
	};
};

export const toGQL = (c: DocumentListItem): DocumentListItemGQL => {
	return {
		...c,
		updatedAt: c.updatedAt?.toISOString() ?? new Date().toISOString(),
	};
};
