import { datadogLogs } from "@datadog/browser-logs";
import { datadogRum } from "@datadog/browser-rum";

import ClientRumSettings from "./data/datadog-rum-env-vars.web.json";
import { clientToken } from "./token";

export type LogLevel = "debug" | "info" | "warn" | "error";

export * from "./electron-log/datadog";

const isProduction = process.env["NODE_ENV"] === "production";
const isLocal = process.env["APP_ENV"] === "local" || process.env["APP_ENV"] === "testing";

// this sets up browser level logging.
export const initLogger = (service: string) => {
	if (isLocal) {
		// We don't want to log to Datadog if we're developing locally
		// because it's too noisy.
		return;
	}

	if (service === "") {
		service = "unknown";
	}

	console.log(`setting up logging with service ${service} app ${process.env["APP_ENV"]}`);

	datadogLogs.init({
		clientToken: clientToken(),
		site: "datadoghq.com",
		service,
		env: process.env["APP_ENV"],
		silentMultipleInit: true,
		// Forward console errors.
		forwardErrorsToLogs: true,
		allowFallbackToLocalStorage: true,
		// We can enable this if we want to send all console logs.
		// forwardConsoleLogs: "all",
	});
};

export const log = (
	message: string,
	content?: Record<string, unknown>,
	level: LogLevel = "info"
): void => {
	async function _() {
		// Extract the first error found in the content record.
		let error: Error | undefined = undefined;
		for (const v of Object.values(content ?? {})) {
			if (v instanceof Error) {
				error = v;
				break;
			}
		}
		switch (level) {
			case "debug": {
				!isProduction && console.debug(`${level.toUpperCase()} ${message}`, content);
				!isLocal && !isProduction && datadogLogs.logger.debug(message, content, error);
				break;
			}
			case "info": {
				!isProduction && console.log(`${level.toUpperCase()} ${message}`, content);
				!isLocal && datadogLogs.logger.info(message, content, error);
				break;
			}
			case "warn": {
				!isProduction && console.warn(`${level.toUpperCase()} ${message}`, content);

				!isLocal && datadogLogs.logger.warn(message, content, error);
				break;
			}
			case "error": {
				!isProduction && console.error(`${level.toUpperCase()} ${message}`, content);
				!isLocal && datadogLogs.logger.error(message, content, error);
				break;
			}
			default: {
				!isProduction && console.error("Unknown log level", { level, ...content });
				!isLocal && datadogLogs.logger.error("Unknown log level", { level, ...content });
				!isLocal && datadogLogs.logger.info(message, content, error);
				break;
			}
		}
	}

	void _();
};

export type UserInfo = {
	// this will be the auth0 ID if the user is logged in.
	id: string;
	email: string | undefined;
	isMoment: boolean;
	loggedIn: boolean;
	name?: string;
};

export const initializeRum = (service: string) => {
	if (service === "") {
		service = "unknown";
	}

	return datadogRum.init({
		applicationId: ClientRumSettings.applicationId,
		clientToken: ClientRumSettings.clientToken,
		// `site` refers to the Datadog site parameter of your organization
		// see https://docs.datadoghq.com/getting_started/site/
		site: "datadoghq.com",
		service,
		env: process.env["APP_ENV"],
		//  version: '1.0.0',
		sessionSampleRate: 100,
		sessionReplaySampleRate: 100,
		trackResources: true,
		trackLongTasks: true,
		trackUserInteractions: true,
		enablePrivacyForActionName: true,
		allowFallbackToLocalStorage: true,
		defaultPrivacyLevel: "allow",
	});
};

export const setUser = (userInfo: UserInfo) => {
	datadogRum.setUser({
		...userInfo,
	});
	datadogLogs.setUser({
		...userInfo,
	});
};

export const setUserProperty = (key: string, value: unknown) => {
	datadogRum.setUserProperty(key, value);
	datadogLogs.setUserProperty(key, value);
};

export const setGlobalContext = (key: string, value: unknown) => {
	datadogLogs.setGlobalContextProperty(key, value);
	datadogRum.setGlobalContextProperty(key, value);
};

export type LogFunc = (message: string, content?: Record<string, unknown>) => void;

export type BaseLogFunc = (
	level: LogLevel,
	message: string,
	content?: Record<string, unknown>
) => void;

/**
 * Javascript Class for use in non-react contexts
 *
 *
 * @param moduleName - string, name of the module to log
 
* @returns Logger - class instance with debug, info, warn, and error methods
 */
export class Logger {
	private moduleNamePrefix: string;

	constructor(moduleName: string) {
		this.moduleNamePrefix = `[${moduleName}] `;
	}

	private baseLogger: BaseLogFunc = (level, message, content) =>
		log(`${this.moduleNamePrefix}${message}`, { level, ...content }, level);

	public debug: LogFunc = (message, content) => this.baseLogger("debug", message, content);

	public info: LogFunc = (message, content) => this.baseLogger("info", message, content);

	public warn: LogFunc = (message, content) => this.baseLogger("warn", message, content);

	public error: LogFunc = (message, content) => this.baseLogger("error", message, content);
}
