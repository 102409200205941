import { add, isAfter } from "date-fns";
import { z } from "zod";

import { generateShortUuid } from "@moment/api-collab/id";

import { log } from "~/utils/datadog/datadog";
import { LOCAL_STORAGE_KEYS } from "~/utils/storage";

/**
 * This is Auth0's recommended flow for storing redirect URLs through the
 * login callback URL.
 * https://auth0.com/docs/secure/attack-protection/state-parameters#use-the-stored-url-to-redirect-users
 *
 * When a user navigates to `/settings` and is not logged in
 * we save a "redirect" entry with a unique ID and store it in the `state` query param
 * the state param is sent back to us from Auth0 and we can use it to
 * look up the URL that sent our user to the login page initially
 *
 * Why session storage?
 *
 * Because Auth0 recommends the following storage methods based on
 * application type.
 *
 * | App Type        | Storage Recommendation |
 * |-----------------|------------------------|
 * | Regular Web App | Cookie or session      |
 * | SPA             | Local browser          |
 * | Native App      | Memory or local        |
 *
 * ------------------------------------------------------------------------
 *
 * The schema for the redirect store.
 *
 * In session storage, we'll store a JSON string that looks like this:
 * {
 *  "id123": {
 *    "id": "id123",
 *    "redirectPath": "/some/path",
 *    "expiresAt": "2021-08-01T00:00:00.000Z"
 *  },
 *  "id456": {
 *    "id": "id456",
 *    "redirectPath": "/another/path",
 *    "expiresAt": "2021-08-01T00:00:00.000Z"
 *  }
 * }
 *
 * Or, Record<string, RedirectStoreEntry>
 */

/**
 * RedirectEntry
 * @description This is a Zod Object schema that we can use to
 * validate the shape of the redirect entry.
 *
 * equivalent to TypeScript's type:
 * type RedirectEntry = {
 *  id: string;
 * 	redirectPath: string;
 * 	expiresAt: string;
 * 	loginUrl: string;
 * }
 */
export const RedirectEntry = z.object({
	id: z.string(), // unique ID for this redirect request
	windowId: z.onumber(), // the window ID to attempt closing
	redirectPath: z.string().min(1), // the path to redirect to
	expiresAt: z.string(), // when the redirect request should be invalidated
	loginUrl: z.string(), // the login url
});

/**
 * RedirectStore
 * @description This is a Zod Record schema that we can use to
 * validate the shape of the redirect store.
 *
 * equivalent to TypeScript's type:
 * type RedirectStore = Record<string, RedirectEntry>
 */
export const RedirectStore = z.record(RedirectEntry);

/**
 * createRedirectEntry
 * @description Creates a redirect entry with a unique ID and an expiration date.
 */
export const createRedirectEntry = (config: Partial<z.infer<typeof RedirectEntry>>) => {
	return {
		id: config.id || generateShortUuid(),
		windowId: config.windowId || undefined,
		redirectPath: config.redirectPath || "/",
		expiresAt: config.expiresAt || add(new Date(), { minutes: 10 }).toISOString(),
		loginUrl: config.loginUrl || "",
	};
};

/**
 * getRedirectStore
 * @description reads the JSON string stored in session storage and
 * parses it into a RedirectStore object using the Zod RedirectStore schema.
 */
export const getRedirectStore = (): z.infer<typeof RedirectStore> => {
	// try {} catch {} because
	//   localStorage.getItem can potentially throw an error
	//   JSON.parse can potentially throw an error
	//   z.ZodRecord.parse can potentially throw an error
	try {
		// read JSON string from session storage
		// if it's null, return a string with an empty object
		const savedValue = localStorage.getItem(LOCAL_STORAGE_KEYS.REDIRECT_STORE) || "{}";

		// parse the JSON string into a JavaScript object
		const parsedValue = JSON.parse(savedValue);

		// validate the parsed object using the RedirectStore schema
		return RedirectStore.parse(parsedValue);
	} catch (e) {
		// we might land here if our JSON string is malformed
		// or if the parsed object doesn't match the RedirectStore schema
		// if so, we'll return an empty object which will be used to
		// initialize the redirect store in session storage again
		log("Error parsing redirect store from session storage", { error: e }, "warn");
		return {};
	}
};

/**
 * findValidRedirectEntry
 * @description Loops over the RedirectStore and returns the first valid redirect entry
 * that has a windowId and is not expired.
 */
export const findValidRedirectEntry = () => {
	const store = getRedirectStore();

	for (const entry of Object.values(store)) {
		// Check if windowId is defined and not expired
		if (entry.windowId !== undefined && Date.now() < new Date(entry.expiresAt).getTime()) {
			return entry; // Return the first matching entry
		}
	}

	return null; // Return null if no valid entry is found
};

export const saveNewWindowId = (id: string, windowId: number) => {
	const entry = getRedirectEntry(id);

	if (entry) {
		saveRedirectEntry({ ...entry, windowId });
	}
};

/**
 * saveRedirectEntry
 * @description saves a redirect entry to the redirect store in session storage.
 */
export const saveRedirectEntry = (entry: z.infer<typeof RedirectEntry>) => {
	// get current state of redirect store
	const state = getRedirectStore();

	// add incoming entry to redirect store
	state[entry.id] = entry;

	// save updated redirect store to session storage
	localStorage.setItem(LOCAL_STORAGE_KEYS.REDIRECT_STORE, JSON.stringify(state));
};

/**
 * deleteRedirectEntry
 * @description deletes a redirect entry from the redirect store in session storage.
 */
export const deleteRedirectEntry = (id: string) => {
	// get current state of redirect store
	const state = getRedirectStore();

	// delete entry from redirect store
	delete state[id];

	// save updated redirect store to session storage
	localStorage.setItem(LOCAL_STORAGE_KEYS.REDIRECT_STORE, JSON.stringify(state));
};

export const clearStore = () => {
	const store = getRedirectStore();

	for (const entry of Object.values(store)) {
		// Check if windowId is defined and not expired
		if (entry.windowId !== undefined) {
			void window.desktopIpcApi?.closeWindow(entry.windowId);
		}
	}

	localStorage.setItem(LOCAL_STORAGE_KEYS.REDIRECT_STORE, JSON.stringify({}));
};

/**
 * getRedirectEntry
 * @description finds redirect entry with matching ID and returns it.
 */
export const getRedirectEntry = (id: string): z.infer<typeof RedirectEntry> | null => {
	// get current state of redirect store
	const state = getRedirectStore();

	// find entry with matching ID
	const entry = state[id];

	// check if entry matches RedirectEntry schema
	const result = RedirectEntry.safeParse(entry);

	// if entry does not match zod schema, return null
	if (!result.success) {
		log("Redirect entry does not match schema.", { issues: result.error.issues }, "warn");
		return null;
	}

	// date[0] should be larger than date[1]
	const validEntry = isAfter(new Date(result.data.expiresAt), new Date());

	// if entry is not valid, delete entry and return null
	if (!validEntry) {
		deleteRedirectEntry(id);
		return null;
	}

	return result.data;
};

// redirectPath logic is always best effort
export const attemptSaveRedirect = (entry: {
	id?: string;
	redirectPath?: string;
	windowId?: number;
	loginUrl?: string;
}) => {
	if (entry.redirectPath || entry.windowId) {
		const redirectEntry = createRedirectEntry({ ...entry });
		try {
			saveRedirectEntry(redirectEntry);
			return redirectEntry;
		} catch (e) {
			log(
				"Failed to save redirect entry before login/logout redirect",
				{ error: e },
				"error"
			);
		}
	}
	return undefined;
};
