import { useQuery } from "@tanstack/react-query";
import { isEmpty } from "lodash";

import { useAuth } from "~/auth/useAuth";
import { docsApiFetch, getDocsApiUrl } from "~/utils/docsapi";

import { permissionsQueryKeys } from ".";
import { useRouteParams } from "../route";
import { LOCAL_WORKSPACE_NAME } from "../workspaces/constants";

export const useCanPublishQuery = (args?: { workspaceName: string; documentId: string }) => {
	const routeParams = useRouteParams();
	const { getAccessTokenSilently, isAuthenticated, isLocalApp } = useAuth();
	const { workspaceName = routeParams.workspaceName, documentId = routeParams.documentId } =
		args ?? {};

	const query = useQuery({
		queryKey: permissionsQueryKeys.canPublish(workspaceName, documentId),
		queryFn: async () => {
			const token = await getAccessTokenSilently(true, routeParams.path);

			if (token === undefined) {
				// don't try to use the query if we can't get a token,
				// but if it occurs we have to return false.
				return false;
			}

			// TODO: Be able to move to a different workspace when you want to publish from local.
			if (workspaceName === LOCAL_WORKSPACE_NAME) {
				return false;
			}

			const response = await docsApiFetch(token, false)(
				`${getDocsApiUrl()}/internal/v1/permissions/${workspaceName}/${documentId}?permission=share`,
				{ method: "GET" }
			);

			if (response.ok) {
				return true;
			} else if (response.status == 403 || response.status == 404) {
				return false;
			}
			throw new Error(response.statusText, { cause: response });
		},
		enabled: isLocalApp && isAuthenticated && !isEmpty(workspaceName) && !isEmpty(documentId),
	});

	return query;
};
