import { useConfirmModal } from "~/components/common/confirm-modal/ConfirmModalContext";

export const useDeleteConfirmModal = () => {
	const { confirm } = useConfirmModal();

	const deleteConfirmation = ({
		name,
		action = "delete",
		confirmLabel = "Delete",
	}: {
		name: string;
		action?: string;
		confirmLabel?: string;
	}) => {
		return confirm({
			content: (
				<>
					Are you sure you want to {action} <strong>{name}</strong>?
				</>
			),
			variant: "negative",
			confirmLabel,
		});
	};

	return { deleteConfirmation };
};
