import { useMutation, useQueryClient } from "@tanstack/react-query";

import { documentQueryKeys } from ".";
import { useArchiveRepoMutation } from "../gitea";
import { useCanWrite } from "../permissions";
import { useUpdateWorkspaceMutation, workspaceQueryKeys } from "../workspaces";
import { LOCAL_WORKSPACE_NAME } from "../workspaces/constants";

export const useDeleteDocumentMutation = ({ workspaceName, documentId }) => {
	const queryClient = useQueryClient();
	const { data: writable } = useCanWrite({
		workspaceName,
		documentId,
	});

	const { updateAsync: updateWorkspace } = useUpdateWorkspaceMutation();
	const { mutateAsync: archiveRepo } = useArchiveRepoMutation();

	const mutation = useMutation({
		mutationKey: documentQueryKeys.deleteDocument,
		mutationFn: async (args: { workspaceName: string; documentId: string }) => {
			if (!writable) {
				throw new Error("User does not have write permissions");
			}
			if (args.workspaceName !== LOCAL_WORKSPACE_NAME) {
				// Archive the repo on Gitea
				await archiveRepo(args);
			}

			// Delete the document from drive
			await window.desktopIpcApi?.deleteLocalDocument(args.workspaceName, args.documentId);

			return updateWorkspace({ workspaceName: args.workspaceName }, (workspace) => {
				if (!workspace) throw new Error("Workspace not found");

				delete workspace.documents[args.documentId];

				return workspace;
			});
		},
		async onSuccess(_data, variables) {
			const location = variables.workspaceName === LOCAL_WORKSPACE_NAME ? "local" : "remote";
			await queryClient.invalidateQueries({
				queryKey: workspaceQueryKeys.workspaces(location, false),
			});
			await queryClient.invalidateQueries({
				queryKey: workspaceQueryKeys.workspaces(location, true),
			});
			await queryClient.invalidateQueries({
				queryKey: workspaceQueryKeys.workspace(location, variables.workspaceName),
			});
		},
	});

	return mutation;
};
