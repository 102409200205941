import NextHead from "next/head";
import { type FC } from "react";

import { getWebBaseURL } from "~/auth/interop/common";
import { toTitleCase } from "~/utils/format/string";

import { BaseSEO } from "./BaseSEO";
import { seoConfig } from "./constants";

export type OpenGraphProps = {
	title: string | null;
	description: string | null;
	url: string | null;
};

const ENV = process.env.APP_ENV;

export const Head: FC<OpenGraphProps> = ({ title, description, url }) => {
	const prefix = ENV === "production" ? "" : `[${ENV}] `;
	// TODO add organizationName to OpenGraphProps, setting to null for now
	const organizationName: string | null = null;
	const baseTitle = `${prefix}${title ?? seoConfig.baseTitle}${
		organizationName ? ` • ${toTitleCase(organizationName)}` : ""
	}`;

	return (
		<>
			<BaseSEO
				config={{
					...seoConfig,
					baseTitle,
					url: `${getWebBaseURL(process.env.APP_ENV)}${url !== null ? url : ""}}`,
					description: description ?? seoConfig.description,
				}}
			/>
			<NextHead>
				<meta name="viewport" content="width=device-width, initial-scale=1.0" />
			</NextHead>
		</>
	);
};
