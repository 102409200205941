export { useCanPublishQuery } from "./useCanPublishQuery";
export { useIsPublishedQuery } from "./useIsPublishedQuery";
export { useRevokeShareMutation } from "./useRevokeShareMutation";
export { useShareMutation } from "./useShareMutation";
export { useCanWrite } from "./useCanWriteQuery";

export const ALL_USERS = "ALL_USERS";

export interface DocPermRequest {
	userType: "AllUsers" | "Username";
	relation: "reader";
	value: string;
}

export const permissionsQueryKeys = {
	base: ["permissions"],
	canPublish: (workspaceName: string, documentId: string) => [
		"permissions",
		workspaceName,
		documentId,
		"canPublish",
	],
	canWrite: (workspaceName: string, documentId: string) => [workspaceName, documentId, "write"],
	isPublished: (workspaceName: string, documentId: string) => [
		"permissions",
		workspaceName,
		documentId,
		"isPublished",
	],
	share: () => [...permissionsQueryKeys.base, "share"],
};
