import { type Auth0ContextInterface, type User as Auth0User, useAuth0 } from "@auth0/auth0-react";
import { useCallback, useEffect, useMemo } from "react";

import { setUserProperty } from "@moment/logging";

import { type User, auth0UserIdToGiteaUserId } from "~/models/user";

import { isDesktopAppMode } from "./interop/common";
import { getLoginAction } from "./loginAction";
import { getLogoutAction } from "./logoutAction";
import { clearStore } from "./redirectStore";

/**
 * Custom hook provider for all auth-related functionality.
 */

/**
 * Context interface for the auth information.
 *
 * This context is very similar to the Auth0Context that that the Auth0 library provides,
 * with some additional overridden properties to meet our implementation. With the
 * exception of `user`, all overridden properties should be functionally identical
 * so that the Auth0 documentation is still usable.
 *
 * Available properties:

 * State booleans (e.g. yes/no):
 * * isAuthenticated
 * * isAuthenticatedOrLocal
 * * isLoading
 * * isLocalApp

 * User data:
 * * user
 * * _Auth0User <-- use this one only if truly necessary

 * Token promises (consider add anything needed from these as well-named getters):
 * * getAccessTokenSilently
 * * getIdTokenClaims

 * Auth workflow funcs. you shouldn't need these if you aren't implementing auth code.
 * * error
 * * getAccessTokenWithPopup
 * * loginWithRedirect
 * * loginWithPopup
 * * logout
 * * handleRedirectCallback
*/
export interface AuthContext
	extends Omit<
		Auth0ContextInterface,
		"user" | "loginWithRedirect" | "logout" | "getAccessTokenSilently"
	> {
	user: User | undefined;
	getAccessTokenSilently: (
		autoLogin?: boolean,
		redirectPath?: string
	) => Promise<string | undefined>;
	isAuthenticatedOrLocal: boolean;
	isLocalApp: boolean;
	loginWithRedirect: (redirectPath?: string) => Promise<void>;
	logoutWithRedirect: (redirectPath?: string) => Promise<void>;
	_Auth0User?: Auth0User;
}

const isLocalApp = isDesktopAppMode();

export const useAuth = (): AuthContext => {
	const {
		getAccessTokenSilently,
		isAuthenticated,
		loginWithRedirect,
		logout,
		user,
		...moreAuth0
	} = useAuth0();

	const isAuthenticatedOrLocal = useMemo(() => isAuthenticated || isLocalApp, [isAuthenticated]);
	const mappedUser = useMemo(() => mapUser(user), [user]);

	const loginAction = useCallback(
		(redirectPath?: string) => {
			const loginAction = getLoginAction(loginWithRedirect, isLocalApp);
			return loginAction(redirectPath);
		},
		[loginWithRedirect]
	);

	const logoutAction = useCallback(
		(redirectPath?: string) => {
			const logoutAction = getLogoutAction(logout);
			return logoutAction(redirectPath);
		},
		[logout]
	);

	const getAccessToken = useCallback(
		(autoLogin?: boolean, redirectPath?: string) => {
			return getAccessTokenSilently().catch((e) => {
				if (autoLogin) {
					console.log(`Login was invalid, redirecting to Login page`);
					void loginAction(redirectPath);
				}
				return Promise.reject(e);
			});
		},
		[getAccessTokenSilently, loginAction]
	);

	useEffect(() => {
		if (isAuthenticated) {
			// if the users authentication state changes, we should clear the redirect store
			// and update datadog with the user information
			clearStore();
			if (mappedUser) {
				setUserProperty("email", mappedUser.email);
				setUserProperty("isMoment", mappedUser.isMomentEmployee);
				setUserProperty("id", mappedUser.id);
				setUserProperty("name", mappedUser.name);
			}
		}
	}, [isAuthenticated, mappedUser]);

	return {
		getAccessTokenSilently: getAccessToken,
		isAuthenticated,
		isAuthenticatedOrLocal,
		isLocalApp,
		loginWithRedirect: loginAction,
		logoutWithRedirect: logoutAction,
		user: mappedUser,
		_Auth0User: user,
		...moreAuth0,
	};
};

// Maps the Auth0User to our existing User interface.
const mapUser = (user: Auth0User | undefined) => {
	if (!user) {
		return undefined;
	}

	const id = user.sub;
	const name = user.name;
	const email = user.email;

	const giteaUserId = user.sub ? auth0UserIdToGiteaUserId(user.sub) : "";
	const nickname = user.nickname || "";
	const givenName = user.given_name || "";
	const familyName = user.family_name || "";
	const profilePicture = user.picture || "";
	const updatedAt = user.updated_at || "";
	const isMomentEmployee = user["http://api.moment.dev/isMoment"] || false;

	const organizationID = "";
	const alternateIDs = null;

	if (!(id && name && email)) {
		console.log(`Auth0 returned a user but expected properties are missing`, {
			id,
			name,
			email,
		});
		return undefined;
	}

	const mappedUser: User = {
		id,
		name,
		email,
		giteaUserId,
		nickname,
		givenName,
		familyName,
		profilePicture,
		updatedAt,
		organizationID,
		alternateIDs,
		isMomentEmployee,
	};

	return mappedUser;
};

export const isTokenError = (e) => {
	return (
		e instanceof Error &&
		"error" in e &&
		(e.error === "missing_refresh_token" || e.error === "invalid_grant")
	);
};
