import cn from "classnames";
import Link from "next/link";
import { type AnchorHTMLAttributes, type CSSProperties, forwardRef } from "react";

import { AccessibleLoadingState } from "./AccessibleLoadingState";
import { type ButtonProps } from "./ButtonProps";
import { hydrateVariant } from "./hydrateVariant";

export interface LinkButtonProps
	// We use `color` for overall button color.
	extends Omit<AnchorHTMLAttributes<HTMLAnchorElement>, "color">,
		ButtonProps {
	href: string;
	disabled?: boolean;
}

/**
 * The standard implementation of a link styled to look like a button, allowing users navigate to
 * other pages with standard mouse, touch, and keyboard inputs.
 *
 * `preset` is the only property you need to set in most cases. It provides sensible defaults for
 * all the properties exposed by this API, *e.g.*, the button `color` and `variant`. When these
 * defaults are not sufficient, you can override any value by providing the relevant properties
 * manually, *e.g.*, `color="negative"` or `variant="outlined"`.
 *
 * Use the `startIcon` and `endIcon` props, rather than providing icons as children. This will
 * provide a more consistent and accessible experience to a wide variety of devices and users,
 * including screenreaders.
 */
export const LinkButton = forwardRef<HTMLAnchorElement, LinkButtonProps>(
	(
		{
			className,
			preset,
			variant,
			size,
			color,
			borderRadius,
			elevation,
			startIcon,
			endIcon,
			children,
			...props
		},
		ref
	) => {
		const toggled = props.toggled;
		const loading = props.loading;

		return (
			<Link
				{...props}
				ref={ref}
				data-toggled={toggled === true}
				data-loading={loading === true}
				className={cn(
					"relative",
					"cursor-pointer",
					"no-underline",
					"hover:underline",
					"focus:underline",
					"active:underline",
					"[&[data-pressed='true']]:underline",
					hydrateVariant({ preset, variant, size, color, borderRadius, elevation }),
					className
				)}
				// HACK: If we do not set this, links on dark mode (only links and only dark mode)
				// have a focus offset ring is colored #fff. Looking at the CSS inspector the
				// classes of the form `ring-offset-*` (e.g., `ring-offset-transparent`) does not
				// look like it exists at all. I'm not sure what's going on. Anyway, this fixes it!
				style={{ "--tw-ring-offset-color": "var(--bg-primary)" } as CSSProperties}
			>
				<AccessibleLoadingState loading={loading} startIcon={startIcon} endIcon={endIcon}>
					{children}
				</AccessibleLoadingState>
			</Link>
		);
	}
);
LinkButton.displayName = "LinkButton";
