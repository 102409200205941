import { useMutation, useQueryClient } from "@tanstack/react-query";
import { useRouter } from "next/router";

import { useAuth } from "~/auth/useAuth";
import { getGiteaApiUrl } from "~/utils/gitea";
import { trpc } from "~/utils/trpc";

import { documentQueryKeys } from "../documents";
import { pageQueryKeys } from "../pages";
import { useCanWrite } from "../permissions";

export const useCloneRepoMutation = ({ workspaceName, documentId }) => {
	const queryClient = useQueryClient();
	const utils = trpc.useUtils();

	const { data: writable } = useCanWrite({
		workspaceName,
		documentId,
	});

	const { getAccessTokenSilently } = useAuth();
	const router = useRouter();

	const { mutateAsync: cloneGitRepo } = trpc.git.clone.useMutation({
		onSuccess: (_, { workspaceName, documentId }) => {
			void Promise.allSettled([
				queryClient.invalidateQueries({
					queryKey: documentQueryKeys.document(workspaceName, documentId),
				}),
				queryClient.invalidateQueries({
					queryKey: pageQueryKeys.documentPages(workspaceName, documentId),
				}),
			]);
		},
		onSettled: () => utils.git.invalidate(),
	});

	const mutation = useMutation({
		mutationFn: async (args: { workspaceName: string; documentId: string }) => {
			const { workspaceName, documentId } = args;

			if (workspaceName === "local") return;

			const remoteUrl = `${getGiteaApiUrl()}/git/${workspaceName}/${documentId}.git`;

			const authToken = await getAccessTokenSilently(true, router.asPath);
			if (!authToken || !writable) return;

			return await cloneGitRepo({ workspaceName, documentId, remoteUrl, authToken });
		},
	});

	return mutation;
};
