import { Copy } from "@carbon/icons-react";
import cx from "classnames";
import { type FC, type HTMLAttributes, useMemo } from "react";

import { BasicTooltip } from "@moment/design-system/Tooltip";

import { IconButton } from "~/ui/Button";

import { copyContent } from "./CopyText";

export type CodeProps = HTMLAttributes<HTMLPreElement | HTMLSpanElement> & {
	content?: string;
	layout?: "inline" | "block";
	copyable?: boolean;
	variant?: "primary" | "secondary";
};

export const CopyCodeButton: FC<{
	content: string;
	layout: CodeProps["layout"];
}> = ({ content }) => {
	return (
		<BasicTooltip content="Copy code">
			<IconButton
				preset="quaternary"
				size="xs"
				onClick={() => copyContent(content)}
				label="Copy this code block"
				icon={<Copy />}
			/>
		</BasicTooltip>
	);
};

export const Code: FC<CodeProps> = ({
	content,
	className,
	layout = "inline",
	copyable = false,
	variant = "primary",
	...props
}) => {
	const containerProps = {
		className: cx(
			"rounded",
			"font-normal",
			layout === "block" && ["relative", "px-4", "py-3"],
			layout === "inline" && ["px-1", "py-0.5"],
			!className?.includes("text-") && ["text-sm"],
			variant === "primary" && ["bg-translucent-tertiary"],
			variant === "secondary" && ["bg-translucent-quaternary"],
			className
		),
		title: content,
		...props,
	};
	const copyButton = useMemo(
		() => (content ? <CopyCodeButton content={content} layout={layout} /> : null),
		[layout, content]
	);

	if (!content) {
		return null;
	}

	if (layout === "block") {
		return (
			<pre {...containerProps}>
				<code>{content}</code>
				{copyable && (
					// Aligned to the center of the first line of the code block.
					<div className="absolute right-2 top-2">{copyButton}</div>
				)}
			</pre>
		);
	}

	if (copyable) {
		return (
			<BasicTooltip content="Click to copy">
				<code
					{...containerProps}
					className={cx(containerProps.className, {
						"hover:bg-translucent-quaternary": variant === "primary",
						"hover:bg-translucent-quinary": variant === "secondary",
					})}
					onClick={() => copyContent(content)}
					role="button"
					tabIndex={0}
					onKeyDown={() => copyContent(content)}
				>
					{content}
				</code>
			</BasicTooltip>
		);
	}

	return <code {...containerProps}>{content}</code>;
};
