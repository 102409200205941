import { useMutation, useQueryClient } from "@tanstack/react-query";

import { useAuth } from "~/auth/useAuth";
import type { User } from "~/models/user";
import { createDocsApiFetch } from "~/utils/docsapi";

import { ALL_USERS, type DocPermRequest, permissionsQueryKeys } from ".";
import { useRouteParams } from "../route";

export const useRevokeShareMutation = () => {
	const queryClient = useQueryClient();

	const { getAccessTokenSilently } = useAuth();
	const router = useRouteParams();

	return useMutation({
		mutationKey: permissionsQueryKeys.share(),
		mutationFn: async ({
			workspaceName,
			documentId,
			user,
		}: {
			workspaceName: string;
			documentId: string;
			user: User | typeof ALL_USERS;
		}) => {
			const token = await getAccessTokenSilently(true, router.path);
			if (!token) return;

			// if granting public permissions, we use a special query parameter to avoid errors
			const allUsersFlag = user === ALL_USERS ? `?all=true` : "";
			const body: DocPermRequest =
				user === ALL_USERS
					? {
							userType: "AllUsers",
							relation: "reader",
							value: "",
						}
					: {
							userType: "Username",
							relation: "reader",
							value: user.giteaUserId,
						};

			const client = createDocsApiFetch(token);
			return await client(`/permissions/${workspaceName}/${documentId}${allUsersFlag}`, {
				method: "DELETE",
				body: JSON.stringify(body),
			});
		},

		// Authzed seems to typically need ~15 seconds to reflect the change in new reads.
		// Rather than slow down the whole system, we can assume that the success response
		// means that it will eventually be correct and immediately update in memory.
		// After a generous 30 seconds, trigger the query invalidation to be sure.
		async onSuccess(_data, variables) {
			queryClient.setQueryData(
				permissionsQueryKeys.isPublished(variables.workspaceName, variables.documentId),
				false
			);

			setTimeout(() => {
				void queryClient.invalidateQueries({
					queryKey: permissionsQueryKeys.isPublished(
						variables.workspaceName,
						variables.documentId
					),
				});
			}, 30000);
		},
	});
};
