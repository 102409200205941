import cn from "classnames";
import { type ReactNode, forwardRef } from "react";

import { AccessibleLoadingState } from "./AccessibleLoadingState";
import { PlainButton, type PlainButtonProps } from "./PlainButton";

export interface IconButtonProps
	extends Omit<PlainButtonProps, "children" | "startIcon" | "endIcon"> {
	className?: string;
	icon: ReactNode;
	label: string;
	disabled?: boolean;
}

export const IconButton = forwardRef<HTMLButtonElement, IconButtonProps>(
	({ className, icon, borderRadius = "full", label, ...props }, ref) => {
		const loading = props.loading;

		let sizeClasses = "!h-8 !w-8";
		switch (props.size) {
			case "xs":
				sizeClasses = "!h-6 !w-6";
				break;
			case "sm":
				sizeClasses = "!h-7 !w-7";
				break;
			case "md":
				sizeClasses = "!h-8 !w-8";
				break;
		}

		return (
			<PlainButton
				{...props}
				ref={ref}
				aria-label={label}
				className={cn(className, sizeClasses)}
				borderRadius={borderRadius}
			>
				<AccessibleLoadingState loading={loading} startIcon={icon} />
			</PlainButton>
		);
	}
);
IconButton.displayName = "IconButton";
