import { type CreateTRPCClient } from "@trpc/client";
import { Node } from "prosemirror-model";
import { EditorState } from "prosemirror-state";

import { schema } from "@moment/api-collab/prosemirror-schema";
import { type AppRouter } from "@moment/desktop-app-ws-api";

import { createPlugins } from "~/components/canvas/easel/prosemirror/plugins";

export const createEditorState = (
	workspaceName: string,
	documentId: string,
	pageId: string,
	doc: JsonObject,
	trpcClient: CreateTRPCClient<AppRouter> | null
) => {
	const state = EditorState.create({
		doc: Node.fromJSON(schema, doc),
		plugins: createPlugins({
			workspaceName,
			documentId,
			pageId,
			schema,
			readonly: false,
			trpcClient,
		}),
	});

	return state;
};
