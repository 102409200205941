import { useMutation } from "@tanstack/react-query";

import { getGiteaApiUrl } from "~/utils/gitea";
import { trpc } from "~/utils/trpc";

export const useAddGitRemoteMutation = () => {
	const { mutateAsync: addRemote } = trpc.git.addRemote.useMutation();

	const mutation = useMutation({
		mutationFn: async (args: { workspaceName: string; documentId: string }) => {
			const { workspaceName, documentId } = args;

			const remoteUrl = `${getGiteaApiUrl()}/git/${workspaceName}/${documentId}.git`;

			return await addRemote({ workspaceName, documentId, remoteUrl });
		},
	});

	return mutation;
};
