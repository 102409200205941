import type { AppState, RedirectLoginOptions } from "@auth0/auth0-react";
import { type NextRouter } from "next/router";

import {
	attemptSaveRedirect,
	deleteRedirectEntry,
	findValidRedirectEntry,
	getRedirectEntry,
	saveNewWindowId,
} from "./redirectStore";

// Use a popup window, intended for electron. This performs the
// authentication in context of the electron app, instead
// of in a separate browser. During the process we will
// not hide the previous page; if the user closes the popup
// then they can immediately resume viewing public pages.
const getPopupAction =
	(loginWithRedirect) =>
	async (appState: AppState, redirectEntry): Promise<number | undefined> => {
		// check if a rediect entry is already present, if so, we will
		const currentRedirectEntry = findValidRedirectEntry();

		if (currentRedirectEntry !== null) {
			const { loginUrl, windowId } = currentRedirectEntry;
			if (windowId && loginUrl) {
				const newWindowId = await window.desktopIpcApi?.focusWindow(windowId, loginUrl);

				// we want to save the old login url, so just update the window id
				if (newWindowId !== windowId) {
					saveNewWindowId(currentRedirectEntry.id, newWindowId);
				}

				return newWindowId;
			}
		}

		let windowId = undefined;

		await loginWithRedirect({
			appState,
			authorizationParams: { prompt: "select_account" },
			async openUrl(url) {
				console.log(`navigating to auth0 login in popup browser window, url=${url}`);
				windowId = await window.desktopIpcApi?.launchWindow(url);
				// if we got back a valid window id, attempt to update the redirect
				// entry with that value so that we can use it again later
				if (windowId && redirectEntry) {
					attemptSaveRedirect({
						id: redirectEntry.id,
						redirectPath: redirectEntry.redirectPath,
						windowId,
						loginUrl: url,
					});
				}
			},
		});

		return windowId;
	};

// Use normal in-browser tab redirect, intended for website.
// This will redirect the tab to the Auth0 auth portal.
// Users can use their browser navigation to return
// if they just want to view public pages.
const getRedirectAction = (loginWithRedirect) => async (appState) => {
	console.log(`navigating to auth0 login in current browser tab`);
	await loginWithRedirect({ appState });
};

// Login will stash the redirect information as redirectId in the "state" query value.
// In the callback, that same "state" value will be provided as the argument.
// If state (redirectId) is not provided, or does not retrieve a redirectPath,
// then login will redirect to landing page.
export const getLoginAction = (
	loginWithRedirect: (options?: RedirectLoginOptions<AppState>) => Promise<void>,
	isLocalApp: boolean
) => {
	const performPopupLogin = getPopupAction(loginWithRedirect);
	const performRedirectLogin = getRedirectAction(loginWithRedirect);
	return async (redirectPath?: string) => {
		const redirectEntry = attemptSaveRedirect({ redirectPath });
		const state = redirectEntry ? { redirectId: redirectEntry.id } : {};
		if (isLocalApp) {
			await performPopupLogin(state, redirectEntry);
		} else {
			await performRedirectLogin(state);
		}
	};
};

// Exported login callback implementation. As described above,
// the onLoginCallback will be given a parameter "appState"
// which contains the redirectId, which can load the redirectEntry.
export const onLoginCallback = (router: NextRouter) => (appState) => {
	const redirectId = appState?.redirectId;

	if (redirectId) {
		const { redirectPath, windowId } = getRedirectEntry(redirectId) || {};
		if (windowId) {
			// we don't care if this passes
			void window.desktopIpcApi?.closeWindow(windowId);
		}

		// lets delete the redirect
		deleteRedirectEntry(redirectId);

		if (redirectPath) {
			void router.replace(redirectPath);
			return;
		}
	}

	void router.replace("/docs");
};
