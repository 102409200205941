import * as React from "react";
import { type Ref, forwardRef } from "react";

import { type ButtonProps } from "./ButtonProps";
import { IconButton, type IconButtonProps } from "./IconButton";
import { LinkButton, type LinkButtonProps } from "./LinkButton";
import { PlainButton, type PlainButtonProps } from "./PlainButton";

/**
 * The standard implementation of a button, allowing users to trigger actions and events with
 * standard mouse, touch, and keyboard inputs.
 *
 * `preset` is the only property you need to set in most cases. It provides sensible defaults for
 * all the properties exposed by this API, *e.g.*, the button `color` and `variant`. When these
 * defaults are not sufficient, you can override any value by providing the relevant properties
 * manually, *e.g.*, `color="negative"` or `variant="outlined"`.
 *
 * Use the `startIcon` and `endIcon` props, rather than providing icons as children. This will
 * provide a more consistent and accessible experience to a wide variety of devices and users,
 * including screenreaders.
 */
const Button = forwardRef((props: PlainButtonProps, ref: Ref<HTMLButtonElement>) => {
	return <PlainButton ref={ref} {...props} />;
});
Button.displayName = "Button";

export {
	Button,
	type ButtonProps,
	LinkButton,
	type LinkButtonProps,
	IconButton,
	type IconButtonProps,
};
