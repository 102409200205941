import cn from "classnames";
import { type ButtonHTMLAttributes, forwardRef } from "react";

import { AccessibleLoadingState } from "./AccessibleLoadingState";
import { type ButtonProps } from "./ButtonProps";
import { hydrateVariant } from "./hydrateVariant";

export interface PlainButtonProps
	extends Omit<
			ButtonHTMLAttributes<HTMLButtonElement>,
			// We use `color` for overall button color.
			| "color"
			// IMPORTANT: Firefox does not allow draggable buttons, so we don't allow them in general.
			| "draggable"
			| "onDrag"
			| "onDragCapture"
			| "onDragEnd"
			| "onDragEndCapture"
			| "onDragEnter"
			| "onDragEnterCapture"
			| "onDragExit"
			| "onDragExitCapture"
			| "onDragLeave"
			| "onDragLeaveCapture"
			| "onDragOver"
			| "onDragOverCapture"
			| "onDragStart"
			| "onDragStartCapture"
		>,
		ButtonProps {
	className?: string;
	disabled?: boolean;
}

export const PlainButton = forwardRef<HTMLButtonElement, PlainButtonProps>(
	(
		{
			className,
			preset,
			variant,
			size,
			color,
			borderRadius,
			elevation,
			startIcon,
			endIcon,
			children,
			...props
		},
		ref
	) => {
		//
		// FUTURE WORK: Ideally we'd like to use `react-aria` stock `Button` or at least the
		// `useButton` hook that supports it. We can't do this yet because it is incompatible with
		// Radix's dropdowns[1]. But there are a lot of good reasons to do this:
		//
		//   - Button accessibility is surprisingly complex, and react-aria handles a lot of corner
		//     cases for us. Because we cannot use `useButton`, we had to re-implement some of what
		//     they do in `<AccessibleLoadingState>`.
		//
		//     For example, in the loading state, we disable pointer events to prevent things like
		//     double-submits, but *DO NOT* disable focus events, because screen readers need this
		//     to reach and read the contents of the button. Similarly we have to clone the contents
		//     into a `sr-only` element so they are visible to screen readers even when they're
		//     invisible to human users.
		//
		//     Note that our implementation is deficient; when loading, react-aria will capture and
		//     cancel things like <enter> events to prevent double-submit. This is important but if
		//     we're going to move to `useButton` anyway it's probably worse to complicate the
		//     implementation for something that's unlikely to arise in the next couple months.
		//
		//   - Button event handling is similarly complex, and we are simply deficient at this now,
		//     particularly for touch devices. One simple example is that if a touch event is
		//     interrupted (by a phone call, or even a React re-render), touch cancellation fails
		//     and the button is stuck in press mode forever. But there are numerous other examples.
		//     Another is that keyboard events do not trigger `active:` state which can lead to odd
		//     behavior and worse accessibility.
		//
		//     react-aria generally abstracts over all of this with a single `onPress` event which
		//     provides a unified API for touch, mouse, keyboard, and assistive technology events.
		//     This is a pretty big deal if you want to run on many kinds of device. For example,
		//     when you touch a button, events fire (in this order): `onTouchStart`, `onTouchEnd`,
		//     `onMouseMove`, `onMouseEnter`, `onMouseDown`, `onMouseUp`, and then `onClick`.
		//     Complicating things, to accommodate gestures like "double tap to zoom", browsers
		//     often introduce a delay `onClick` to see if a double-tap occurs, which makes handling
		//     these events manually really tricky.
		//
		//     These concerns are not theoretical; occasionally we have found ourselves intercepting
		//     `onMouseDown` events for some reason or another and this has lead to complex bugs,
		//     and even exposed bugs in Radix. Having a single, unified API that works on all
		//     devices and browsers will largely prevent us from spending a week per year randomly
		//     on issues like this.
		//
		// [1]: https://github.com/adobe/react-spectrum/issues/5643
		//

		const toggled = props.toggled;
		const loading = props.loading;

		return (
			<button
				{...props}
				ref={ref}
				data-toggled={toggled === true}
				data-loading={loading === true}
				type={props.type ?? "button"}
				className={cn(
					"relative",
					"cursor-pointer",
					hydrateVariant({ preset, variant, size, color, borderRadius, elevation }),
					className
				)}
			>
				<AccessibleLoadingState loading={loading} startIcon={startIcon} endIcon={endIcon}>
					{children}
				</AccessibleLoadingState>
			</button>
		);
	}
);
PlainButton.displayName = "PlainButton";
