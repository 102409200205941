import cn from "classnames";
import { type FC, type PropsWithChildren, type ReactNode } from "react";

import { LoadingSpinner } from "../LoadingSpinner";

const loadingSpinnerClass = "loading-spinner absolute";

/**
 * Renders a button's contents or a loading state when the button's `loading` prop is `true`. If
 * there is *EITHER* a `startIcon` or `endIcon`, the loading state spinner will replace the icon; if
 * there is no icon or both icons, the loading spinner will be overlayed over the button contents.
 *
 * Special care is taken to make sure the loading transition is accessible to a wide variety of
 * devices and users. For example, when the button enters a loading state:
 *
 *   - the button size does not change
 *   - the button remains focusable, so that screenreaders can report the button contents, but with
 *     `pointer-events: none`, to prevent users from double-clicking
 *   - the loading state is announced to screen readers
 *   - when the loading state is visible, we duplicate the contents in a `sr-only` element, so that
 *     screenreaders can still report the button contents
 */
export const AccessibleLoadingState: FC<
	{ loading?: boolean; startIcon?: ReactNode; endIcon?: ReactNode } & PropsWithChildren
> = ({ loading, startIcon, endIcon, children }) => {
	//
	// Just display contents if not loading.
	//

	if (loading !== true) {
		return (
			<>
				{startIcon}
				{children}
				{endIcon}
			</>
		);
	}

	//
	// When there is a `startIcon`, a loading state spinner should replace the `startIcon`. Like
	// this, with `O` representing the loading spinner:
	//
	//     +--------------+
	//     | O  My Button |
	//     +--------------+
	//
	if (startIcon) {
		return (
			<>
				{loading && (
					<LoadingSpinner className={cn(loadingSpinnerClass, "spinner-left")} size="xs" />
				)}
				<span className="contents invisible" aria-hidden>
					{startIcon}
				</span>
				{children}
				{endIcon}
			</>
		);
	}

	//
	// Similarly, when there is ONLY an `endIcon` (i.e., no `startIcon`), a loading state spinner should
	// replace the `endIcon`:
	//
	//     +--------------+
	//     | My Button  O |
	//     +--------------+
	//
	if (endIcon && !startIcon) {
		return (
			<>
				{children}
				<span className="contents invisible" aria-hidden>
					{endIcon}
				</span>
				{loading && (
					<LoadingSpinner
						className={cn(loadingSpinnerClass, "spinner-right")}
						size="xs"
					/>
				)}
			</>
		);
	}

	// Buttons should not change size when loading. From an accessibility standpoint, this is
	// annoyingly hard:
	//
	//   - `display: contents` removes the content from the accessibility tree in SOME browsers.
	//   - We want the contents to be visible to screen readers in ALL browsers.
	//   - So, we set `aria-hidden` to hide this element from the accessibility tree, and then add
	//     it back with `sr-only` below.
	const correctlySizedButInvisibleAndAriaInaccessibleContents = (
		<span className="contents invisible" aria-hidden>
			{startIcon}
			{children}
			{endIcon}
		</span>
	);

	// Display button contents to screen readers.
	const screenReaderVisibleContents = (
		<span className="sr-only">
			{startIcon}
			{children}
			{endIcon}
		</span>
	);

	return (
		<>
			{correctlySizedButInvisibleAndAriaInaccessibleContents}
			{screenReaderVisibleContents}
			<LoadingSpinner className={loadingSpinnerClass} size="xs" />
		</>
	);
};
