import { isEmpty } from "lodash";
import { useRouter } from "next/router";
import { useMemo } from "react";

export const useRouteParams = () => {
	const router = useRouter();

	return useMemo(() => {
		const workspaceName = router.query["workspaceName"];
		const documentId = router.query["documentId"];
		const pageSlug = router.query["pageSlug"];
		const path = router.asPath;

		return {
			workspaceName: isEmpty(workspaceName) ? "" : `${workspaceName}`,
			documentId: isEmpty(documentId) ? "" : `${documentId}`,
			pageSlug: isEmpty(pageSlug) ? "" : `${pageSlug}`,
			path,
		};
	}, [router.asPath, router.query]);
};
