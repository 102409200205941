import { useMutation } from "@tanstack/react-query";

import type { DocumentMetadata } from "@moment/api-collab/api-types";

import { useLogger } from "~/components/canvas/hooks/useLogger";
import { trpc } from "~/utils/trpc";

import { useUpdateDocumentMutation } from "../documents";

export const useReorderPagesMutation = ({ workspaceName, documentId }) => {
	const logger = useLogger("useReorderPagesMutation");
	const utils = trpc.useUtils();

	const { updateAsync: updateDocument } = useUpdateDocumentMutation({
		workspaceName,
		documentId,
	});

	const mutation = useMutation({
		mutationFn: async (args: {
			workspaceName: string;
			documentId: string;
			reorderInfo: { pageId: string; parentPageID: string | null }[];
		}) => {
			const { workspaceName, documentId, reorderInfo } = args;

			return updateDocument(
				{
					workspaceName,
					documentId,
				},
				(document) => {
					if (!document) {
						logger.error("Document not found", args);
						return document;
					}

					const sortedPages = reorderInfo.reduce<DocumentMetadata["pagesMetadata"]>(
						(acc, { pageId, parentPageID }) => {
							const page = document.pagesMetadata.find((page) => page.id === pageId);
							if (page) {
								acc.push({
									...page,
									parentPageID,
								});
							}
							return acc;
						},
						[]
					);

					document.pagesMetadata = sortedPages;

					return document;
				}
			);
		},
		onSuccess: () => void utils.git.invalidate(),
	});

	return mutation;
};
