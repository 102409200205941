import {
	type DehydratedState,
	HydrationBoundary,
	QueryClient,
	QueryClientProvider,
} from "@tanstack/react-query";
import {
	type CreateTRPCClient,
	createTRPCClient,
	createWSClient,
	httpLink,
	loggerLink,
	splitLink,
	wsLink,
} from "@trpc/client";
import { type ServerInfo } from "ipc-api";
import { isEmpty } from "lodash";
import dynamic from "next/dynamic";
import { usePathname } from "next/navigation";
import { createContext, useCallback, useContext, useEffect, useState } from "react";
import { useEffectOnce } from "react-use";

import { isTokenError, useAuth } from "~/auth/useAuth";
import { createEditorState } from "~/components/canvas/easel/editor-state/createEditorState";
import { editorLoaded } from "~/store/editors/slice";
import { cellCompilationTriggeredThunk } from "~/store/editors/thunks";
import type { StoredPage } from "~/store/editors/types";
import { useAppDispatch } from "~/store/hooks";
import { type AppRouter, trpc } from "~/utils/trpc";

const queryClient = new QueryClient({
	defaultOptions: {
		queries: {
			throwOnError: false,
			staleTime: Infinity,
			retry: (_, e) => !isTokenError(e),
		},
	},
});

export const QueryProvider = ({
	children,
	dehydratedState,
}: {
	children: React.ReactNode;
	dehydratedState: DehydratedState;
}) => {
	const dispatch = useAppDispatch();
	const pathname = usePathname();
	const { getAccessTokenSilently } = useAuth();

	const [trpcClient, setTrpcClient] = useState(() =>
		trpc.createClient({
			links: [],
		})
	);
	const [vanillaTrpcClient, setVanillaTrpcClient] = useState<null | CreateTRPCClient<AppRouter>>(
		null
	);
	const [atlasInfo, setAtlasInfo] = useState<null | ServerInfo>(null);

	useEffectOnce(() => {
		window.desktopIpcApi?.onAtlasInfo((info) => {
			setAtlasInfo(info);
		});
	});

	// Initialize the tRPC WebSocket client
	useEffectOnce(() => {
		window.desktopIpcApi?.onWebSocketInfo((info) => {
			const wsClient = createWSClient({
				url: `ws://${info.address.address}:${info.address.port}`,
				connectionParams: {
					token: info.token,
				},
				keepAlive: {
					enabled: true,
					intervalMs: 30000,
					pongTimeoutMs: 5000,
				},
			});

			const links = [
				// Log all tRPC errors to the console
				loggerLink({
					enabled: (opts) => opts.direction === "down" && opts.result instanceof Error,
				}),

				// Splitbased on the operation type, using the WebSocket link only for subscriptions
				splitLink({
					condition: (op) => op.type === "subscription",
					true: wsLink({
						client: wsClient,
					}),
					false: httpLink({
						url: `http://${info.address.address}:${info.address.port}`,
						headers: async () => ({
							"x-access-token": await getAccessTokenSilently(),
						}),
					}),
				}),
			];

			setVanillaTrpcClient(() =>
				createTRPCClient<AppRouter>({
					links,
				})
			);

			setTrpcClient(
				trpc.createClient({
					links,
				})
			);
		});
	});

	// Hydrate the editor state for SSR'ed pages
	const hydrateState = useCallback(() => {
		if (!isEmpty(dehydratedState)) {
			dehydratedState.queries
				.filter((query) => query.queryKey[0] === "pages" && query.queryKey[1] === "content")
				.forEach((query) => {
					const workspaceName = (query.queryKey[2] as string | undefined) ?? "";
					const documentId = (query.queryKey[3] as string | undefined) ?? "";
					const pageId = (query.queryKey[4] as string | undefined) ?? "";

					if (!isEmpty(pageId)) {
						const stored = query.state.data as StoredPage;

						dispatch(
							editorLoaded({
								pageId,
								editorState: createEditorState(
									workspaceName,
									documentId,
									pageId,
									stored.doc,
									vanillaTrpcClient
								),
								lastAccessed: new Date(),
							})
						);
						void dispatch(cellCompilationTriggeredThunk({ pageId }));
					}
				});
		}
	}, [dehydratedState, dispatch, vanillaTrpcClient]);

	// eslint-disable-next-line react-hooks/exhaustive-deps
	useEffect(hydrateState, [pathname]);

	return (
		<trpc.Provider client={trpcClient} queryClient={queryClient}>
			<QueryClientProvider client={queryClient}>
				<HydrationBoundary state={dehydratedState}>
					<VanillaTrpcContext.Provider value={vanillaTrpcClient}>
						<AtlasFetchContext.Provider value={atlasInfo}>
							{children}

							<DevTools />
						</AtlasFetchContext.Provider>
					</VanillaTrpcContext.Provider>
				</HydrationBoundary>
			</QueryClientProvider>
		</trpc.Provider>
	);
};

const VanillaTrpcContext = createContext<CreateTRPCClient<AppRouter> | null>(null);

const AtlasFetchContext = createContext<ServerInfo | null>(null);

// this context exists merely to avoid prop-drilling, but I still feel gross
// about it
export function useAtlasInfo() {
	return useContext(AtlasFetchContext);
}

export function useTrpcClient() {
	return useContext(VanillaTrpcContext);
}

const DevTools = () => {
	if (process.env.NODE_ENV === "development") {
		const ReactQueryDevtools = dynamic(
			() => import("@tanstack/react-query-devtools").then((m) => m.ReactQueryDevtools),
			{
				ssr: false,
			}
		);

		return <ReactQueryDevtools initialIsOpen={false} />;
	}

	return null;
};
