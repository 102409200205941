import { giteaApi as createApi } from "gitea-js";

import { createDocsApiFetch, docsApiFetch, getDocsApiUrl } from "~/utils/docsapi";

import { type Repo, type Workspace } from "./types";

export const getGiteaApiUrl = getDocsApiUrl;

export const giteaClient = (token?: string) =>
	createApi(getGiteaApiUrl(), {
		customFetch: docsApiFetch(token ?? ""),
	});

export const queryReposByWorkspace = async (
	token: string,
	workspaceName: string
): Promise<Array<Repo>> => {
	const docsapiClient = createDocsApiFetch(token);
	const repos = await docsapiClient(`/repos?workspace=${encodeURIComponent(workspaceName)}`, {
		method: "GET",
	});

	return repos;
};

export const getWorkspaces = async (token: string): Promise<Array<Workspace>> => {
	const docsapiClient = createDocsApiFetch(token);
	const repos = await docsapiClient(`/workspaces`, { method: "GET" });

	return repos;
};

export const getPublicRepos = async (): Promise<Array<Repo>> => {
	const url = `${getGiteaApiUrl()}/public/v1/help/repos`;
	const fetch = docsApiFetch("");
	const response = await fetch(url, { method: "GET" });
	if (response.ok) {
		return response.json();
	}
	return [];
};

export const getPublicWorkspaces = async (): Promise<Array<Workspace>> => {
	const url = `${getGiteaApiUrl()}/public/v1/workspaces`;
	const fetch = docsApiFetch("");
	const response = await fetch(url, { method: "GET" });
	return response.json();
};

export const createRepo = async (
	token: string,
	workspaceName: string,
	documentID: string
): Promise<Repo> => {
	const docsapiClient = createDocsApiFetch(token);
	const repo = await docsapiClient(`/repos/${encodeURIComponent(workspaceName)}`, {
		method: "POST",
		body: JSON.stringify({ documentID }),
	});

	return repo;
};
