import { useQuery } from "@tanstack/react-query";
import { isEmpty, merge } from "lodash";
import { useNetworkState } from "react-use";

import type { CanvasListingItem as CanvasListingItemGQL } from "~/api/generated/graphql";
import { isDesktopAppMode } from "~/auth/interop/common";
import { useAuth } from "~/auth/useAuth";
import { useLogger } from "~/components/canvas/hooks/useLogger";
import type { Workspace } from "~/data/workspaces";
import { fromGQL as canvasListingFromGQL } from "~/models/CanvasListingItem";
import { queryReposByWorkspace } from "~/utils/gitea";

import { WorkspaceSchema, workspaceQueryKeys } from ".";
import { useRouteParams } from "../route";
import { FILE_SYSTEM_NAMESPACE, LOCAL_WORKSPACE_NAME } from "./constants";
import { getTitle } from "./repos";

export const useWorkspaceQuery = (args: { workspaceName: string }) => {
	const routeParams = useRouteParams();

	const { workspaceName = routeParams.workspaceName } = args;

	const logger = useLogger("useWorkspaceQuery");

	const { isAuthenticated, getAccessTokenSilently } = useAuth();
	const { online } = useNetworkState();

	const remoteWorkspaceQuery = useQuery({
		queryKey: workspaceQueryKeys.workspace("remote", workspaceName),
		queryFn: async (): Promise<Workspace | undefined> => {
			try {
				const token = await getAccessTokenSilently(true, routeParams.path);

				// If there is no token, we should not be able to fetch remote workspaces
				if (!token) {
					logger.debug("No token, cannot fetch remote workspace", args);
					return;
				}

				const repos = await queryReposByWorkspace(token, workspaceName);
				return WorkspaceSchema.parse({
					id: workspaceName,
					name: workspaceName,
					documents: Object.fromEntries(
						Object.values(repos).map((repo) => {
							return [
								repo.name,
								{
									id: repo.name ?? "",
									title: getTitle(repo),
									createdAt: new Date(repo.created_at ?? 0),
									updatedAt: new Date(repo.updated_at ?? 0),
									organizationID: workspaceName,
									ownerID: "",
								},
							];
						})
					),
				});
			} catch {
				return WorkspaceSchema.parse({
					id: workspaceName,
					name: workspaceName,
					documents: {},
				});
			}
		},
		enabled: isAuthenticated && online && workspaceName !== LOCAL_WORKSPACE_NAME,
	});

	const localWorkspaceQuery = useQuery({
		queryKey: workspaceQueryKeys.workspace(FILE_SYSTEM_NAMESPACE, workspaceName),
		queryFn: async (): Promise<Workspace | null> => {
			const ipcWorkspace = await window.desktopIpcApi?.fetchLocalWorkspace(workspaceName);
			if (!ipcWorkspace) {
				logger.error("Failed to fetch local workspace", args);
				throw new Error(`Failed to fetch local workspace: ${workspaceName}`);
			}

			return WorkspaceSchema.parse({
				...ipcWorkspace,
				id: FILE_SYSTEM_NAMESPACE,
				name: isEmpty(workspaceName) ? LOCAL_WORKSPACE_NAME : workspaceName,
				documents: Object.fromEntries(
					Object.values(ipcWorkspace.documents).map((value) => {
						return [
							value.id,
							canvasListingFromGQL(value as unknown as CanvasListingItemGQL),
						];
					})
				),
			});
		},
		enabled: isDesktopAppMode(),
	});

	return {
		data: merge(remoteWorkspaceQuery.data, localWorkspaceQuery.data),
		isFetching: localWorkspaceQuery.isFetching && remoteWorkspaceQuery.isFetching,
		isSuccess: localWorkspaceQuery.isSuccess && remoteWorkspaceQuery.isSuccess,
		isLoading: localWorkspaceQuery.isLoading || remoteWorkspaceQuery.isLoading,
		isError: localWorkspaceQuery.isError || remoteWorkspaceQuery.isError,
		refetch: () => Promise.all([localWorkspaceQuery.refetch(), remoteWorkspaceQuery.refetch()]),
	};
};
