import { isEmpty } from "lodash";

import { isDesktopAppMode } from "~/auth/interop/common";
import { trpc } from "~/utils/trpc";

export const useGitRemotes = (args: { workspaceName: string; documentId: string }) => {
	const { workspaceName, documentId } = args;

	const query = trpc.git.remotes.useQuery(
		{ workspaceName, documentId },
		{
			staleTime: 1000 * 60 * 5, // 5 minutes
			enabled: isDesktopAppMode() && !isEmpty(workspaceName) && !isEmpty(documentId),
		}
	);

	return query;
};
