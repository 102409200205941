import cn from "classnames";
import type { FC } from "react";

export const LoadingSpinnerSizeOptions = ["xs", "sm", "md", "lg"] as const;
export type LoadingSpinnerSize = (typeof LoadingSpinnerSizeOptions)[number];

export type LoadingSpinnerProps = {
	className?: string;
	size?: LoadingSpinnerSize;
};
const spinnerSizePx: {
	[key in LoadingSpinnerSize]: number;
} = {
	xs: 12,
	sm: 16,
	md: 20,
	lg: 24,
};

export const LoadingSpinner: FC<LoadingSpinnerProps> = ({ className, size = "sm" }) => {
	const sizePx = spinnerSizePx[size];
	const opacity = "opacity-80";

	return (
		<svg
			className={cn(className, "animate-spin")}
			xmlns="http://www.w3.org/2000/svg"
			fill="none"
			viewBox="0 0 24 24"
			width={sizePx}
			height={sizePx}
		>
			<circle
				className="opacity-10"
				cx="12"
				cy="12"
				r="10"
				stroke="currentColor"
				strokeWidth="4"
			></circle>
			<path
				className={opacity}
				fill="currentColor"
				d="M4 12a8 8 0 018-8V0C5.373 0 0 5.373 0 12h4zm2 5.291A7.962 7.962 0 014 12H0c0 3.042 1.135 5.824 3 7.938l3-2.647z"
			></path>
		</svg>
	);
};
