export { useUpdateWorkspaceMutation } from "./useUpdateWorkspaceMutation";
export { useWorkspaceQuery } from "./useWorkspaceQuery";
export { useWorkspacesQuery } from "./useWorkspacesQuery";

export * from "./types";

export const workspaceQueryKeys = {
	workspaces: (location: "local" | "remote" | "help", fetchDocumentData = false) => [
		"workspaces",
		location,
		...(fetchDocumentData ? ["fetchDocumentData"] : []),
	],

	// As of today, remote is ONLY for the workspaces fetched from the docsapi.
	// Local is for the entire file system (not just for the workspaces in /workspaces/local).
	// Thus, if you need to update a workspace, you cannot do it with remote, as it is read-only.
	workspace: (location: "local" | "remote", workspaceName: string) => [
		"workspaces",
		location,
		workspaceName,
	],
	updateWorkspace: ["workspaces", "updateWorkspace"],
};
