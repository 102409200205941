import { Auth0Provider } from "@auth0/auth0-react";
import { useRouter } from "next/router";
import { type FC, type PropsWithChildren } from "react";

import { getAuthConfigs } from "./authconfig";
import { getAuthURLs } from "./interop/common";
import { onLoginCallback } from "./loginAction";

export const AuthProvider: FC<PropsWithChildren> = ({ children }) => {
	const authConfig = getAuthConfigs(process.env.AUTH_ENV);
	const { loginRedirectUri } = getAuthURLs(process.env.APP_ENV);

	const router = useRouter();

	return (
		<Auth0Provider
			domain={authConfig.auth0Domain}
			clientId={authConfig.auth0ClientId}
			authorizationParams={{ redirect_uri: loginRedirectUri, audience: authConfig.audience }}
			onRedirectCallback={onLoginCallback(router)}
			cacheLocation={"localstorage"}
			useRefreshTokens
		>
			{children}
		</Auth0Provider>
	);
};
