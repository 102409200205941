import { type InputRule, ellipsis, emDash, inputRules, smartQuotes } from "prosemirror-inputrules";
import { type MarkType, type NodeType, type Schema } from "prosemirror-model";

import { blockQuoteRule } from "./blockQuoteRule";
import { codeBlockRule } from "./codeBlockRule";
import { emUnderscoreRule } from "./emUnderscoreRule";
import { headingRule } from "./headingRule";
import { horizontalRuleRule } from "./horizontalRuleRule";
import { inlineCodeRule } from "./inlineCodeRule";
import { kbdRule } from "./kbdRule";
import { linkRuleMd } from "./linkRuleMd";
import { linkRuleUrl } from "./linkRuleUrl";
import { listItemRule } from "./listItemRule";
import { specialCharacters } from "./specialCharacters";
import { strikeTextRule } from "./strikeTextRule";
import { strongAsterixRule } from "./strongAsterixRule";
import { toDoRule } from "./toDoRule";

// A set of input rules for creating the basic block quotes, lists,
// code blocks, and heading.
export const buildInputRules = (schema: Schema) => {
	const rules = smartQuotes.concat(ellipsis, emDash);

	function addIfPresent<T extends MarkType | NodeType>(t: T | undefined, f: (t: T) => InputRule) {
		if (t) rules.push(f(t));
	}

	// Nodes
	addIfPresent(schema.nodes["horizontal_rule"], horizontalRuleRule);
	addIfPresent(schema.nodes["blockquote"], blockQuoteRule);
	addIfPresent(schema.nodes["list_item"], listItemRule);
	addIfPresent(schema.nodes["to_do_item"], toDoRule);
	addIfPresent(schema.nodes["code_block"], codeBlockRule);
	addIfPresent(schema.nodes["heading"], (t: NodeType) => headingRule(t, 6));

	// Marks
	addIfPresent(schema.marks["link"], linkRuleMd);
	addIfPresent(schema.marks["link"], linkRuleUrl);

	addIfPresent(schema.marks["strong"], strongAsterixRule);
	addIfPresent(schema.marks["em"], emUnderscoreRule);
	addIfPresent(schema.marks["strike"], strikeTextRule);
	addIfPresent(schema.marks["kbd"], kbdRule);
	addIfPresent(schema.marks["code"], inlineCodeRule);

	// Character Replacement
	rules.push(...specialCharacters);

	return inputRules({ rules });
};
