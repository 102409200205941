import { combineReducers } from "redux";

import { canvas } from "./canvas/slice";
import { cells } from "./cells/slice";
import { client } from "./client/slice";
import { codeEditor } from "./code-editor/slice";
import { desktopAppAPIs } from "./desktop-app/api";
import { desktopApp } from "./desktop-app/slice";
import { editors } from "./editors/slice";
import { pageStorageSlice } from "./pageStorage/pageStorageSlice";
import { reducer as roles } from "./roles/slice";

export const reducer = combineReducers({
	canvas,
	cells,
	client,
	codeEditor,
	desktopApp,
	editors,
	[pageStorageSlice.reducerPath]: pageStorageSlice.reducer,
	roles,
	[desktopAppAPIs.reducerPath]: desktopAppAPIs.reducer,
});
