import { isEmpty } from "lodash";

import { getWebBaseURL, isDesktopAppMode } from "~/auth/interop/common";

export const EnvValues: Array<NonNullable<MomentAppEnv>> = [
	"local",
	"staging",
	"production",
	"testing",
];
export type Env = (typeof EnvValues)[number];

const envSet = new Set(EnvValues);

export function isValidEnv(o?: unknown): o is Env {
	return typeof o === "string" && envSet.has(o as Env);
}

const alpha = "ABCDEFGHIJKLMNOPQRSTUVWXYZabcdefghijklmnopqrstuvwxyz";
export const gensym = (length: number): string =>
	Array.from({ length }, () => Math.floor(Math.random() * alpha.length - 1))
		.map((n) => alpha[n])
		.join("");

export const sleep = (n: number) => {
	const ms = Math.abs(Math.floor(n));
	return new Promise((resolve) => setTimeout(resolve, ms));
};

export const isNotNullOrUndefined = <T>(x: T | null | undefined): x is T =>
	x !== null && x !== undefined;

export const getServerSideUrl = () => {
	if (isEmpty(process.env["VERCEL_URL"])) {
		return "http://localhost:3333";
	}
	return `https://${process.env["VERCEL_URL"]}`;
};

export const getAppBaseUrl = () => {
	if (isDesktopAppMode()) {
		return getWebBaseURL();
	}

	return getServerSideUrl();
};
