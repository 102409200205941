export { useCreatePageMutation } from "./useCreatePageMutation";
export { useRenamePageMutation } from "./useRenamePageMutation";
export { useReorderPagesMutation } from "./useReorderPagesMutation";
export { useTogglePageArchivedMutation } from "./useTogglePageArchivedMutation";

export const pageQueryKeys = {
	base: ["pages"],
	updatePage: ["pages", "updatePage"],
	documentPages: (workspaceName: string, documentId: string) => [
		"pages",
		workspaceName,
		documentId,
	],
	pageContent: (workspaceName: string, documentId: string, pageId: string) => [
		"pages",
		"content",
		workspaceName,
		documentId,
		pageId,
	],
	pageAuthor: (workspaceName: string, documentId: string, pageId: string) => [
		"pages",
		"author",
		workspaceName,
		documentId,
		pageId,
	],
	relatedFile: (workspaceName: string, documentId: string, pageId: string, file: string) => [
		"pages",
		workspaceName,
		documentId,
		pageId,
		file,
	],
};
