import { useMutation, useQueryClient } from "@tanstack/react-query";

import { makeToast } from "@moment/design-system/Toast";

import { useLogger } from "~/components/canvas/hooks/useLogger";
import { fromGQL } from "~/models/CanvasListingItem";
import { trpc } from "~/utils/trpc";

import { documentQueryKeys } from ".";
import { useAddGitRemoteMutation, usePushRepoMutation } from "../git";
import { useCreateRepoMutation } from "../gitea";
import { useUpdateWorkspaceMutation, workspaceQueryKeys } from "../workspaces";
import { FILE_SYSTEM_NAMESPACE, LOCAL_WORKSPACE_NAME } from "../workspaces/constants";

export const useCreateDocumentMutation = () => {
	const logger = useLogger("useCreateDocumentMutation");

	const queryClient = useQueryClient();
	const utils = trpc.useUtils();

	const { mutateAsync: initRepo } = trpc.git.init.useMutation();
	const { updateAsync: updateWorkspace } = useUpdateWorkspaceMutation();
	const { mutateAsync: createRemoteRepo } = useCreateRepoMutation();
	const { mutateAsync: addGitRemote } = useAddGitRemoteMutation();
	const { mutateAsync: pushChanges } = usePushRepoMutation();

	const mutation = useMutation({
		mutationKey: documentQueryKeys.createDocument,
		mutationFn: async (args: { workspaceName: string }) => {
			const { workspaceName } = args;

			const document = await window.desktopIpcApi?.createNewDocument(workspaceName);
			if (!document) throw new Error("Failed to create new local document");
			const data = fromGQL(document);

			// Initialize the git repo on disk
			try {
				await initRepo({ workspaceName, documentId: document.id });
			} catch (error) {
				logger.error("Failed to initialize git repo", { error });
				throw error;
			}

			const workspace = await updateWorkspace(
				{ workspaceName, checkFilesystem: true },
				(workspace) => {
					if (!workspace) {
						return;
					}

					workspace.documents[document.id] = data;

					return workspace;
				}
			);

			logger.info("Created document", { documentId: document.id, workspaceName });
			if (workspace && workspaceName !== LOCAL_WORKSPACE_NAME) {
				try {
					await createRemoteRepo({
						documentId: document.id,
						workspaceName,
					});
					await addGitRemote({
						workspaceName,
						documentId: document.id,
					});
					await pushChanges({
						workspaceName,
						documentId: document.id,
					});
				} catch (error) {
					logger.error("Error creating remote repo", { error });
					throw error;
				}
			}

			return data;
		},
		async onSuccess({ id: _ }, { workspaceName }) {
			// Invalidate the queries for both the workspace on the filesystem and on the git remote server.
			void Promise.all([
				queryClient.invalidateQueries({
					queryKey: workspaceQueryKeys.workspaces(FILE_SYSTEM_NAMESPACE, false),
				}),
				queryClient.invalidateQueries({
					queryKey: workspaceQueryKeys.workspaces("remote", true),
				}),
				queryClient.invalidateQueries({
					queryKey: workspaceQueryKeys.workspace(FILE_SYSTEM_NAMESPACE, workspaceName),
				}),
				queryClient.invalidateQueries({
					queryKey: workspaceQueryKeys.workspace("remote", workspaceName),
				}),
				utils.git.invalidate(),
			]);
		},
		async onError(error) {
			logger.error("Failed to create document", { error });
			makeToast({
				message: `Failed to create document: ${error.message}`,
				variant: "error",
			});
		},
	});

	return mutation;
};
