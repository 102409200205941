import { useQuery } from "@tanstack/react-query";
import { isEmpty } from "lodash";

import { useAuth } from "~/auth/useAuth";
import { docsApiFetch, getDocsApiUrl } from "~/utils/docsapi";

import { permissionsQueryKeys } from ".";
import { useRouteParams } from "../route";
import { LOCAL_WORKSPACE_NAME } from "../workspaces/constants";

export const useCanWrite = (args: { workspaceName: string; documentId: string }) => {
	const routeParams = useRouteParams();
	const { getAccessTokenSilently } = useAuth();
	const { workspaceName = routeParams.workspaceName, documentId = routeParams.documentId } = args;

	const query = useQuery({
		queryKey: permissionsQueryKeys.canWrite(workspaceName, documentId),
		queryFn: async () => {
			// If you are in a local workspace, you can always write.
			if (workspaceName === LOCAL_WORKSPACE_NAME) {
				return true;
			}

			let token: string | undefined;
			try {
				token = await getAccessTokenSilently(false, routeParams.path);
				// if you do not have a token, and you are not in the local workspace,
				// you cannot write.
				if (!token) return false;
			} catch (error) {
				return false;
			}

			if (isEmpty(documentId)) {
				return false;
			}

			const response = await docsApiFetch(token, false)(
				`${getDocsApiUrl()}/internal/v1/permissions/${workspaceName}/${documentId}?permission=write`,
				{ method: "GET" }
			);

			if (response.ok) {
				return true;
			} else if (response.status == 403 || response.status == 404) {
				return false;
			}
			throw new Error(response.statusText, { cause: response });
		},
		enabled: !isEmpty(workspaceName),
	});

	return query;
};
