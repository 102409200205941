import { cva } from "class-variance-authority";

import {
	type BorderRadius,
	type ButtonProps,
	type Color,
	type Elevation,
	type NonNullableButtonProps,
	type Preset,
	type Size,
	type Variant,
} from "./ButtonProps";

const OFFSET_RING_CLASSES = [
	// HACK: Override the global a:active styles by using a more specific CSS specifier here.
	"hack-override-default-focus-ring",
	"[&.hack-override-default-focus-ring]:focus-visible:ring-2",
	"[&.hack-override-default-focus-ring]:focus-visible:ring-offset-2",
	"[&.hack-override-default-focus-ring]:focus:ring-2",
	"[&.hack-override-default-focus-ring]:focus:ring-offset-2",
	"[&.hack-override-default-focus-ring]:active:ring-2",
	"[&.hack-override-default-focus-ring]:active:ring-offset-2",
];

const NO_OFFSET_RING_CLASSES = [
	// HACK: Override the global a:active styles by using a more specific CSS specifier here.
	"hack-override-default-focus-ring",
	"[&.hack-override-default-focus-ring]:focus-visible:ring-1",
	"[&.hack-override-default-focus-ring]:focus-visible:ring-offset-0",
	"[&.hack-override-default-focus-ring]:focus:ring-1",
	"[&.hack-override-default-focus-ring]:focus:ring-offset-0",
	"[&.hack-override-default-focus-ring]:active:ring-1",
	"[&.hack-override-default-focus-ring]:active:ring-offset-0",
];

export const variants = cva(
	[
		"border",
		"inline-flex",
		"items-center",
		"justify-center",
		"whitespace-nowrap",
		"text-sm",
		"font-normal",
		"transition-colors",
		"disabled:pointer-events-none",
		"disabled:opacity-50",
		"[&_svg]:pointer-events-none",
		"[&_svg]:shrink-0",

		"transition-shadow",
		"duration-200",
		"ring-0",
		"ring-offset-0",
		"ring-offset-background",
		"focus:outline-none",
		"focus-visible:outline-none",
		"focus-visible:ring-2",
		"focus-visible:ring-ring",
		"focus-visible:ring-offset-2",

		// NOTE: Allow focus while loading so screen readers can still read the button contents.
		"[&[data-loading='true']]:cursor-not-allowed",
		"[&[data-loading='true']]:pointer-events-none",

		"[&[data-loading='true']]:opacity-70",
	],
	{
		variants: {
			color: {
				brand: "",
				contrast: "",
				gray: "",
				negative: "",
			},
			variant: {
				"filled": "",
				"filled-subtle": "",
				"outlined": "",
				"outlined-subtle": "",
				"text": "",
			},
			bgColor: {
				"brand--filled": [
					"bg-palette-brand-9",
					"hover:bg-palette-brand-10",
					"focus:bg-palette-brand-10",
					"active:bg-palette-brand-9",
					"[&[data-pressed='true']]:bg-palette-brand-9",
				],
				"brand--filled-subtle": [
					"bg-palette-brand-3",
					"hover:bg-palette-brand-4",
					"focus:bg-palette-brand-4",
					"active:bg-palette-brand-5",
					"[&[data-pressed='true']]:bg-palette-brand-5",
				],
				"brand--outlined": [
					"bg-palette-brand-3",
					"hover:bg-palette-brand-4",
					"focus:bg-palette-brand-4",
					"active:bg-palette-brand-5",
					"[&[data-pressed='true']]:bg-palette-brand-5",
				],
				"brand--outlined-subtle": [
					"bg-palette-brand-2",
					"hover:bg-palette-brand-3",
					"focus:bg-palette-brand-3",
					"active:bg-palette-brand-4",
					"[&[data-pressed='true']]:bg-palette-brand-4",
				],
				"brand--text": [
					"bg-transparent",
					"hover:bg-palette-brand-3",
					"focus:bg-palette-brand-3",
					"active:bg-palette-brand-4",
					"[&[data-pressed='true']]:bg-palette-brand-4",
				],

				"contrast--filled": [
					"bg-palette-contrast-9",
					"hover:bg-palette-contrast-10",
					"focus:bg-palette-contrast-10",
					"active:bg-palette-contrast-9",
					"[&[data-pressed='true']]:bg-palette-contrast-9",
				],
				"contrast--filled-subtle": [
					"bg-palette-contrast-3",
					"hover:bg-palette-contrast-4",
					"focus:bg-palette-contrast-4",
					"active:bg-palette-contrast-5",
					"[&[data-pressed='true']]:bg-palette-contrast-5",
				],
				"contrast--outlined": [
					"bg-palette-contrast-3",
					"hover:bg-palette-contrast-4",
					"focus:bg-palette-contrast-4",
					"active:bg-palette-contrast-5",
					"[&[data-pressed='true']]:bg-palette-contrast-5",
				],
				"contrast--outlined-subtle": [
					"bg-palette-contrast-1",
					"hover:bg-palette-contrast-3",
					"focus:bg-palette-contrast-3",
					"active:bg-palette-contrast-4",
					"[&[data-pressed='true']]:bg-palette-contrast-4",
				],
				"contrast--text": [
					"bg-transparent",
					"hover:bg-palette-contrast-3",
					"focus:bg-palette-contrast-3",
					"active:bg-palette-contrast-4",
					"[&[data-pressed='true']]:bg-palette-contrast-4",
				],

				"gray--filled": [
					"bg-palette-gray-9",
					"hover:bg-palette-gray-10",
					"focus:bg-palette-gray-10",
					"active:bg-palette-gray-9",
					"[&[data-pressed='true']]:bg-palette-gray-9",
				],
				"gray--filled-subtle": [
					"bg-palette-gray-3",
					"hover:bg-palette-gray-4",
					"focus:bg-palette-gray-4",
					"active:bg-palette-gray-5",
					"[&[data-pressed='true']]:bg-palette-gray-5",
				],
				"gray--outlined": [
					"bg-palette-gray-3",
					"hover:bg-palette-gray-4",
					"focus:bg-palette-gray-4",
					"active:bg-palette-gray-5",
					"[&[data-pressed='true']]:bg-palette-gray-5",
				],
				"gray--outlined-subtle": [
					"bg-palette-gray-1",
					"hover:bg-palette-gray-3",
					"focus:bg-palette-gray-3",
					"active:bg-palette-gray-4",
					"[&[data-pressed='true']]:bg-palette-gray-4",
				],
				"gray--text": [
					"bg-transparent",
					"hover:bg-palette-gray-3",
					"focus:bg-palette-gray-3",
					"active:bg-palette-gray-4",
					"[&[data-pressed='true']]:bg-palette-gray-4",
					"[&[data-toggled='true']]:bg-palette-gray-3",
				],

				"negative--filled": [
					"bg-palette-negative-9",
					"hover:bg-palette-negative-10",
					"focus:bg-palette-negative-10",
					"active:bg-palette-negative-9",
					"[&[data-pressed='true']]:bg-palette-negative-9",
				],
				"negative--filled-subtle": [
					"bg-palette-negative-3",
					"hover:bg-palette-negative-4",
					"focus:bg-palette-negative-4",
					"active:bg-palette-negative-5",
					"[&[data-pressed='true']]:bg-palette-negative-5",
				],
				"negative--outlined": [
					"bg-palette-negative-3",
					"hover:bg-palette-negative-4",
					"focus:bg-palette-negative-4",
					"active:bg-palette-negative-5",
					"[&[data-pressed='true']]:bg-palette-negative-5",
				],
				"negative--outlined-subtle": [
					"bg-palette-negative-2",
					"hover:bg-palette-negative-3",
					"focus:bg-palette-negative-3",
					"active:bg-palette-negative-4",
					"[&[data-pressed='true']]:bg-palette-negative-4",
				],
				"negative--text": [
					"bg-transparent",
					"hover:bg-palette-negative-3",
					"focus:bg-palette-negative-3",
					"active:bg-palette-negative-4",
					"[&[data-pressed='true']]:bg-palette-negative-4",
				],
			},
			borderColor: {
				"brand--filled": [
					"border-palette-brand-9",
					"hover:border-palette-brand-10",
					"focus:border-palette-brand-10",
					"active:border-palette-brand-9",
					"[&[data-pressed='true']]:border-palette-brand-9",
				],
				"brand--filled-subtle": [
					"border-palette-brand-3",
					"hover:border-palette-brand-4",
					"focus:border-palette-brand-9",
					"active:border-palette-brand-9",
					"[&[data-pressed='true']]:border-palette-brand-9",
				],
				"brand--outlined": [
					"border-palette-brand-8",
					"hover:border-palette-brand-8",
					"focus:border-palette-brand-9",
					"active:border-palette-brand-9",
					"[&[data-pressed='true']]:border-palette-brand-9",
				],
				"brand--outlined-subtle": [
					"border-palette-brand-8",
					"hover:border-palette-brand-8",
					"focus:border-palette-brand-9",
					"active:border-palette-brand-9",
					"[&[data-pressed='true']]:border-palette-brand-9",
				],
				"brand--text": [
					"border-transparent",
					"border-transparent",
					"focus:border-palette-brand-9",
					"active:border-palette-brand-9",
					"[&[data-pressed='true']]:border-palette-brand-9",
				],

				"contrast--filled": [
					"border-palette-contrast-9",
					"hover:border-palette-contrast-10",
					"focus:border-palette-contrast-10",
					"active:border-palette-contrast-9",
					"[&[data-pressed='true']]:border-palette-contrast-9",
				],
				"contrast--filled-subtle": [
					"border-palette-contrast-3",
					"hover:border-palette-contrast-4",
					"focus:border-palette-contrast-9",
					"active:border-palette-contrast-9",
					"[&[data-pressed='true']]:border-palette-contrast-9",
				],
				"contrast--outlined": [
					"border-palette-contrast-8",
					"hover:border-palette-contrast-8",
					"focus:border-palette-contrast-9",
					"active:border-palette-contrast-9",
					"[&[data-pressed='true']]:border-palette-contrast-9",
				],
				"contrast--outlined-subtle": [
					"border-palette-contrast-8",
					"hover:border-palette-contrast-8",
					"focus:border-palette-contrast-9",
					"active:border-palette-contrast-9",
					"[&[data-pressed='true']]:border-palette-contrast-9",
				],
				"contrast--text": [
					"border-transparent",
					"border-transparent",
					"focus:border-palette-contrast-9",
					"active:border-palette-contrast-9",
					"[&[data-pressed='true']]:border-palette-contrast-9",
				],

				"gray--filled": [
					"border-palette-gray-9",
					"hover:border-palette-gray-10",
					"focus:border-palette-brand-10",
					"active:border-palette-brand-9",
					"[&[data-pressed='true']]:border-palette-brand-9",
				],
				"gray--filled-subtle": [
					"border-palette-gray-3",
					"hover:border-palette-gray-4",
					"focus:border-palette-brand-9",
					"active:border-palette-brand-9",
					"[&[data-pressed='true']]:border-palette-brand-11",
				],
				"gray--outlined": [
					"border-palette-gray-8",
					"hover:border-palette-gray-8",
					"focus:border-palette-brand-9",
					"active:border-palette-brand-9",
					"[&[data-pressed='true']]:border-palette-brand-9",
				],
				"gray--outlined-subtle": [
					"border-palette-gray-8",
					"hover:border-palette-gray-9",
					"focus:border-palette-brand-9",
					"active:border-palette-brand-9",
					"[&[data-pressed='true']]:border-palette-brand-9",
				],
				"gray--text": [
					"border-transparent",
					"border-transparent",
					"focus:border-palette-brand-9",
					"active:border-palette-brand-9",
					"[&[data-pressed='true']]:border-palette-brand-9",
				],

				"negative--filled": [
					"border-palette-negative-9",
					"hover:border-palette-negative-10",
					"focus:border-palette-negative-10",
					"active:border-palette-negative-9",
					"[&[data-pressed='true']]:border-palette-negative-9",
				],
				"negative--filled-subtle": [
					"border-palette-negative-3",
					"hover:border-palette-negative-4",
					"focus:border-palette-negative-9",
					"active:border-palette-negative-9",
					"[&[data-pressed='true']]:border-palette-negative-9",
				],
				"negative--outlined": [
					"border-palette-negative-8",
					"hover:border-palette-negative-8",
					"focus:border-palette-negative-9",
					"active:border-palette-negative-9",
					"[&[data-pressed='true']]:border-palette-negative-9",
				],
				"negative--outlined-subtle": [
					"border-palette-negative-8",
					"hover:border-palette-negative-8",
					"focus:border-palette-negative-9",
					"active:border-palette-negative-9",
					"[&[data-pressed='true']]:border-palette-negative-9",
				],
				"negative--text": [
					"border-transparent",
					"border-transparent",
					"focus:border-palette-negative-9",
					"active:border-palette-negative-9",
					"[&[data-pressed='true']]:border-palette-negative-9",
				],
			},
			textColor: {
				"brand--filled": [
					"text-fixed-light-default",
					"hover:text-fixed-light-hover",
					"focus:text-fixed-light-focus",
					"active:text-fixed-light-active",
					"[&[data-pressed='true']]:text-fixed-light-active",
				],
				"brand--filled-subtle": [
					"text-palette-brand-11",
					"hover:text-palette-brand-11",
					"focus:text-palette-brand-12",
					"active:text-palette-brand-12",
					"[&[data-pressed='true']]:text-palette-brand-12",
				],
				"brand--outlined": [
					"text-palette-brand-11",
					"hover:text-palette-brand-11",
					"focus:text-palette-brand-12",
					"active:text-palette-brand-12",
					"[&[data-pressed='true']]:text-palette-brand-12",
				],
				"brand--outlined-subtle": [
					"text-palette-brand-11",
					"hover:text-palette-brand-11",
					"focus:text-palette-brand-12",
					"active:text-palette-brand-12",
					"[&[data-pressed='true']]:text-palette-brand-12",
				],
				"brand--text": [
					"text-palette-brand-11",
					"hover:text-palette-brand-11",
					"focus:text-palette-brand-12",
					"active:text-palette-brand-12",
					"[&[data-pressed='true']]:text-palette-brand-12",
				],

				"contrast--filled": [
					"text-palette-contrast-2",
					"hover:text-palette-contrast-1",
					"focus:text-palette-contrast-1",
					"active:text-palette-contrast-1",
					"[&[data-pressed='true']]:text-palette-contrast-1",
				],
				"contrast--filled-subtle": [
					"text-palette-contrast-12",
					"hover:text-palette-contrast-12",
					"focus:text-palette-contrast-12",
					"active:text-palette-contrast-12",
					"[&[data-pressed='true']]:text-palette-contrast-12",
				],
				"contrast--outlined": [
					"text-palette-contrast-12",
					"hover:text-palette-contrast-12",
					"focus:text-palette-contrast-12",
					"active:text-palette-contrast-12",
					"[&[data-pressed='true']]:text-palette-contrast-12",
				],
				"contrast--outlined-subtle": [
					"text-palette-contrast-12",
					"hover:text-palette-contrast-12",
					"focus:text-palette-contrast-12",
					"active:text-palette-contrast-12",
					"[&[data-pressed='true']]:text-palette-contrast-12",
				],
				"contrast--text": [
					"text-palette-contrast-12",
					"hover:text-palette-contrast-12",
					"focus:text-palette-contrast-12",
					"active:text-palette-contrast-12",
					"[&[data-pressed='true']]:text-palette-contrast-12",
				],

				"gray--filled": [
					"text-palette-gray-2",
					"hover:text-palette-gray-1",
					"focus:text-palette-gray-1",
					"active:text-palette-gray-1",
					"[&[data-pressed='true']]:text-palette-gray-1",
				],
				"gray--filled-subtle": [
					"text-palette-gray-12",
					"hover:text-palette-gray-12",
					"focus:text-palette-gray-12",
					"active:text-palette-gray-12",
					"[&[data-pressed='true']]:text-palette-gray-12",
				],
				"gray--outlined": [
					"text-palette-gray-12",
					"hover:text-palette-gray-12",
					"focus:text-palette-gray-12",
					"active:text-palette-gray-12",
					"[&[data-pressed='true']]:text-palette-gray-12",
				],
				"gray--outlined-subtle": [
					"text-palette-gray-12",
					"hover:text-palette-gray-12",
					"focus:text-palette-gray-12",
					"active:text-palette-gray-12",
					"[&[data-pressed='true']]:text-palette-gray-12",
				],
				"gray--text": [
					"text-palette-gray-12",
					"hover:text-palette-gray-12",
					"focus:text-palette-gray-12",
					"active:text-palette-gray-12",
					"[&[data-pressed='true']]:text-palette-gray-12",
				],

				"negative--filled": [
					"text-fixed-light-default",
					"hover:text-fixed-light-hover",
					"focus:text-fixed-light-focus",
					"active:text-fixed-light-active",
					"[&[data-pressed='true']]:text-fixed-light-active",
				],
				"negative--filled-subtle": [
					"text-palette-negative-11",
					"hover:text-palette-negative-11",
					"focus:text-palette-negative-12",
					"active:text-palette-negative-12",
					"[&[data-pressed='true']]:text-palette-negative-12",
				],
				"negative--outlined": [
					"text-palette-negative-11",
					"hover:text-palette-negative-11",
					"focus:text-palette-negative-12",
					"active:text-palette-negative-12",
					"[&[data-pressed='true']]:text-palette-negative-12",
				],
				"negative--outlined-subtle": [
					"text-palette-negative-11",
					"hover:text-palette-negative-11",
					"focus:text-palette-negative-12",
					"active:text-palette-negative-12",
					"[&[data-pressed='true']]:text-palette-negative-12",
				],
				"negative--text": [
					"text-palette-negative-11",
					"hover:text-palette-negative-11",
					"focus:text-palette-negative-12",
					"active:text-palette-negative-12",
					"[&[data-pressed='true']]:text-palette-negative-12",
				],
			},
			fillColor: {
				"brand--filled": [],
				"brand--filled-subtle": [],
				"brand--outlined": [],
				"brand--outlined-subtle": [],
				"brand--text": [],

				"contrast--filled": [],
				"contrast--filled-subtle": [],
				"contrast--outlined": [],
				"contrast--outlined-subtle": [],
				"contrast--text": [],

				"gray--filled": [],
				"gray--filled-subtle": [
					"[&_svg]:text-palette-gray-9",
					"[&_svg]:hover:text-palette-gray-10",
					"[&_svg]:focus:text-palette-gray-11",
					"[&_svg]:active:text-palette-gray-12",
					"[&_svg]:[&[data-pressed='true']]:text-palette-gray-12",
				],
				"gray--outlined": [
					"[&_svg]:text-palette-gray-9",
					"[&_svg]:hover:text-palette-gray-10",
					"[&_svg]:focus:text-palette-gray-11",
					"[&_svg]:active:text-palette-gray-12",
					"[&_svg]:[&[data-pressed='true']]:text-palette-gray-12",
				],
				"gray--outlined-subtle": [
					"[&_svg]:text-palette-gray-9",
					"[&_svg]:hover:text-palette-gray-10",
					"[&_svg]:focus:text-palette-gray-11",
					"[&_svg]:active:text-palette-gray-12",
					"[&_svg]:[&[data-pressed='true']]:text-palette-gray-12",
				],
				"gray--text": [
					"[&_svg]:text-palette-gray-9",
					"[&_svg]:hover:text-palette-gray-10",
					"[&_svg]:focus:text-palette-gray-11",
					"[&_svg]:active:text-palette-gray-12",
					"[&_svg]:[&[data-pressed='true']]:text-palette-gray-12",
				],

				"negative--filled": [],
				"negative--filled-subtle": [],
				"negative--outlined": [],
				"negative--outlined-subtle": [],
				"negative--text": [],
			},
			focusRingColor: {
				brand: ["ring-palette-brand-9", "ring-offset-palette-brand-1"],
				contrast: ["ring-palette-contrast-9", "ring-offset-palette-contrast-1"],
				gray: ["ring-palette-brand-9", "ring-offset-palette-gray-1"],
				negative: ["ring-palette-negative-9", "ring-offset-palette-negative-1"],
			},
			focusRingOffset: {
				"filled": OFFSET_RING_CLASSES,
				"filled-subtle": NO_OFFSET_RING_CLASSES,
				"outlined": NO_OFFSET_RING_CLASSES,
				"outlined-subtle": NO_OFFSET_RING_CLASSES,
				"text": NO_OFFSET_RING_CLASSES,
			},
			borderRadius: {
				default: "rounded-md",
				full: "rounded-full",
			},
			size: {
				xs: [
					"h-6",
					"px-1.5",
					"py-0",
					"text-xs",
					"gap-1.5",
					"[&_svg:not(.loading-spinner)]:size-3",
					"[&_svg.loading-spinner.spinner-left]:left-3",
					"[&_svg.loading-spinner.spinner-right]:right-3",
				],
				sm: [
					"h-7",
					"px-2",
					"py-0",
					"text-xs",
					"gap-1.5",
					"[&_svg:not(.loading-spinner)]:size-4",
					"[&_svg.loading-spinner.spinner-left]:left-3",
					"[&_svg.loading-spinner.spinner-right]:right-3",
				],
				md: [
					"h-8",
					"px-3",
					"py-0",
					"text-sm",
					"gap-1.5",
					"[&_svg:not(.loading-spinner)]:size-4",
					"[&_svg.loading-spinner.spinner-left]:left-3",
					"[&_svg.loading-spinner.spinner-right]:right-3",
				],
			},
			elevation: {
				raised: "shadow-sm hover:shadow",
				flat: "shadow-none",
			},
		},
	}
);

/**
 * Accepts a `preset` and returns a baseline `ButtonProps` object with `color`, `variant`, `size`,
 * etc., set. Once the preset `ButtonProps` is obtained, individual props can be overridden
 * individually. For example, a user might write something like;
 *
 *     <Button preset="primary" color="contrast" />
 *
 * which would result in the `primary` preset, with the `color` being overridden to `contrast`.
 */
function fillPreset(
	preset: Preset,
	_variant: Variant | undefined
): Omit<NonNullableButtonProps, "preset" | "loading" | "toggled" | "startIcon" | "endIcon"> {
	let variant = _variant;
	let color: Color;
	const size: Size = "md";
	const borderRadius: BorderRadius = "default";
	let elevation: Elevation = "raised";

	switch (preset) {
		case "primary":
			variant ??= "filled";
			color = "brand";
			break;
		case "secondary":
			variant ??= "outlined-subtle";
			color = "gray";
			break;
		case "tertiary":
			variant ??= "filled-subtle";
			color = "gray";
			elevation = "flat";
			break;
		case "quaternary":
			variant ??= "text";
			color = "gray";
			elevation = "flat";
			break;
		case "destructive":
			variant ??= "filled";
			color = "negative";
			break;
	}

	return { variant, color, size, borderRadius, elevation };
}

/**
 * Takes `ButtonProps` as an argument and returns a set of CSS classes that can be applied to the
 * `Button` component.
 */
export function hydrateVariant(props: ButtonProps) {
	const preset = fillPreset(props.preset ?? "secondary", props.variant);
	let { color, size, borderRadius, elevation } = preset;

	color = props.color ?? color;
	size = props.size ?? size;
	borderRadius = props.borderRadius ?? borderRadius;
	if (preset.variant !== "filled-subtle") {
		elevation = props.elevation ?? elevation;
	}

	return variants({
		...props,
		color,
		variant: preset.variant,
		bgColor: `${color}--${preset.variant}`,
		size,
		borderColor: `${color}--${preset.variant}`,
		borderRadius,
		elevation,
		textColor: `${color}--${preset.variant}`,
		fillColor: `${color}--${preset.variant}`,
		focusRingColor: color,
		focusRingOffset: preset.variant,
	});
}
