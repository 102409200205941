import { useQuery } from "@tanstack/react-query";
import { isEmpty } from "lodash";
import { useCallback } from "react";

import { type DocumentMetadata, documentMetadata } from "@moment/api-collab/api-types";

import { isDesktopAppMode } from "~/auth/interop/common";
import { getAppBaseUrl } from "~/utils/common";

import { documentQueryKeys } from ".";
import { useCloneRepoMutation } from "../git/useCloneRepoMutation";
import { useCanWrite } from "../permissions";
import { useRouteParams } from "../route";

export const queryDocument = async (
	workspaceName: string,
	documentId: string
): Promise<DocumentMetadata> => {
	const response = await fetch(
		`${getAppBaseUrl()}/api/public/${workspaceName}/${documentId}/moment.yml`
	);
	if (!response.ok) {
		throw new Error("Failed to fetch public document metadata");
	}

	return await response.json();
};

export const useDocumentQuery = (args: { workspaceName: string; documentId: string }) => {
	const routeParams = useRouteParams();

	const { workspaceName = routeParams.workspaceName, documentId = routeParams.documentId } = args;
	const {
		data: writable,
		status,
		isLoading,
		error,
	} = useCanWrite({
		workspaceName: args.workspaceName,
		documentId: args.documentId,
	});

	const { mutateAsync: cloneGitRepo } = useCloneRepoMutation({
		workspaceName,
		documentId,
	});

	const getDocumentMetadata = useCallback(async () => {
		try {
			return await window.desktopIpcApi?.fetchLocalDocumentMetadata(
				workspaceName,
				documentId
			);
		} catch (error) {
			return null;
		}
	}, [workspaceName, documentId]);

	const query = useQuery<DocumentMetadata>({
		queryKey: documentQueryKeys.document(workspaceName, documentId),

		queryFn: async () => {
			if (status !== "success" && error) {
				console.log("failed to fetch write permissions", error);
				throw error;
			}

			if (!isDesktopAppMode() || !writable) {
				return await queryDocument(workspaceName, documentId);
			}

			let document = await getDocumentMetadata();
			if (!document && writable) {
				await cloneGitRepo({ workspaceName, documentId });

				// Try again
				document = await getDocumentMetadata();
			}

			if (!document) {
				throw new Error("Failed to fetch document metadata");
			}

			return documentMetadata.parse(document);
		},

		enabled: !isEmpty(workspaceName) && !isEmpty(documentId) && !isLoading,
	});

	return query;
};
