import type { LogoutOptions } from "@auth0/auth0-react";
import { type NextRouter } from "next/router";

import { getAuthConfigs } from "./authconfig";
import { getAuthURLs } from "./interop/common";
import { attemptSaveRedirect, getRedirectEntry } from "./redirectStore";

// We want to use the APP_ENV here as moment://-/ does not work unless you build the electron app
const { logoutRedirectUri } = getAuthURLs(process.env.APP_ENV);
const { auth0ClientId } = getAuthConfigs(process.env.AUTH_ENV);

// Logout will stash the redirectId as the query parameter "redirectId".
// logoutParams.returnTo must be a consistent, allowlisted URL, and
// we pass the intended redirect target via the redirectEntry.
export const getLogoutAction = (logout: (options?: LogoutOptions) => Promise<void>) => {
	return async (redirectPath?: string) => {
		const redirectEntry = attemptSaveRedirect({ redirectPath });

		// this is the URL that Auth0 will redirect to, from there we use the
		// redirectId to further redirect within the app.
		const redirectUrl = `${logoutRedirectUri}${redirectEntry ? `?redirectId=${redirectEntry.id}` : ""}`;
		console.log(`attempting logout with redirect url ${redirectUrl}`);

		await logout({
			clientId: auth0ClientId,
			logoutParams: {
				returnTo: redirectUrl,
				// Ideally, we want to log users out completely.
				// However, the returnTo does not get passed into the Github logout flow.
				// I believe this is a bug in Auth0, as you see similar issues with SAML providers.
				// https://community.auth0.com/t/saml-federated-logout-redirect-not-working/125115
				// For development, when you need a full logout, you can try the button below.
			},
		});
	};
};
//
// Exported logout callback. As describe above, the onLogoutCallback
// will retrieve the redirectId from the query parameters, which can
// load the redirectEntry.
export const onLogoutCallback = (router: NextRouter) => {
	const returnId = router.query["redirectId"];
	if (returnId && typeof returnId === "string") {
		const redirectPath = getRedirectEntry(returnId)?.redirectPath;
		if (redirectPath) {
			void router.replace(redirectPath);
			return;
		}
	}

	void router.replace("/docs");
};
