import { useMutation, useQueryClient } from "@tanstack/react-query";
import { produce } from "immer";

import type { DocumentMetadata } from "@moment/api-collab/api-types";

import type { CanvasMetadata as CanvasMetadataGQL } from "~/api/generated/graphql";
import { useLogger } from "~/components/canvas/hooks/useLogger";
import { sluggify } from "~/utils/helpers/string";
import { trpc } from "~/utils/trpc";

import { documentQueryKeys } from ".";
import { useCanWrite } from "../permissions";
import { useUpdateWorkspaceMutation } from "../workspaces";

export const useUpdateDocumentMutation = ({ workspaceName, documentId }) => {
	const logger = useLogger("useUpdateDocumentMutation");

	const utils = trpc.useUtils();

	const { data: writable } = useCanWrite({
		workspaceName,
		documentId,
	});

	const queryClient = useQueryClient();

	const { updateAsync: updateWorkspace } = useUpdateWorkspaceMutation();

	const mutation = useMutation({
		mutationKey: documentQueryKeys.updateDocument,
		mutationFn: async (args: { workspaceName: string; document: DocumentMetadata }) => {
			const { workspaceName, document } = args;
			if (!writable) {
				logger.error("User does not have write permissions");
				return;
			}

			if (document.title !== "") {
				const prettyName = sluggify(document.title);
				const oldprettyName = document.prettyName;

				try {
					// if the prettyname is taken, makeSymlinkForDocumentDir will append a number to make the directory unique
					const result = await window.desktopIpcApi?.makeSymlinkForDocumentDir(
						workspaceName,
						document.id,
						prettyName,
						oldprettyName
					);

					if (result) {
						document.prettyName = result;
					}
				} catch (e) {
					logger.error("failed to create symlink", {
						error: e,
					});
				}
			}
			await window.desktopIpcApi?.writeLocalDocumentMetadata(
				workspaceName,
				document.id,
				document as CanvasMetadataGQL
			);

			return document;
		},
		onSuccess: async (document, { workspaceName }) => {
			await updateWorkspace({ workspaceName }, (workspace) => {
				if (!workspace) {
					logger.error("Workspace not found");
					return;
				}

				const workspaceDocument = workspace.documents[documentId];
				if (!workspaceDocument) {
					logger.error("Document not found");
					return;
				}

				workspace.documents[documentId] = {
					...workspaceDocument,
					title: document?.title ?? "",
					updatedAt: new Date(),
				};

				return workspace;
			});

			await utils.git.invalidate();
		},
	});

	return {
		...mutation,
		updateAsync: (
			args: { workspaceName: string; documentId: string },
			updaterFn: (document: DocumentMetadata | undefined) => DocumentMetadata | undefined
		) => {
			const document = queryClient.setQueryData<DocumentMetadata>(
				documentQueryKeys.document(args.workspaceName, args.documentId),
				(draft) => produce(draft, updaterFn)
			);

			if (!document) {
				logger.error("Document not found");
				return;
			}

			return mutation.mutateAsync({
				...args,
				document,
			});
		},
	};
};
