import { type AnyAction, type ThunkDispatch, configureStore } from "@reduxjs/toolkit";
import { useStore } from "react-redux";

import { desktopAppAPIs } from "./desktop-app/api";
import { exceptionHandler } from "./middlewares";
import { pageStorageSlice } from "./pageStorage/pageStorageSlice";
import { reducer } from "./reducer";

type StoreConfig = {
	logErrors: boolean;
};

const IS_PRODUCTION = process.env.APP_ENV === "production";

export const makeStore = ({ logErrors }: StoreConfig) => {
	return configureStore({
		reducer,
		devTools: !IS_PRODUCTION,
		middleware: (getDefaultMiddleware) =>
			getDefaultMiddleware({
				serializableCheck: false,
			})
				.concat(pageStorageSlice.middleware)
				.concat(desktopAppAPIs.middleware)
				.concat(exceptionHandler(logErrors)),
	});
};

export type AppThunkConfig = { state: RootState };

export const store = makeStore({ logErrors: true });
export type Store = typeof store;

export const useAppStore = (): Store => {
	return useStore();
};

export type RootState = ReturnType<typeof store.getState>;

export type ThunkArgs = {
	state: RootState;
};

export type AppDispatch = typeof store.dispatch;

export type AppThunkDispatch = ThunkDispatch<RootState, unknown, AnyAction>;
