import { useMutation, useQueryClient } from "@tanstack/react-query";

import { useLogger } from "~/components/canvas/hooks/useLogger";

import { documentQueryKeys, useUpdateDocumentMutation } from ".";
import { useUpdateWorkspaceMutation, workspaceQueryKeys } from "../workspaces";
import { FILE_SYSTEM_NAMESPACE } from "../workspaces/constants";

export const useRenameDocumentMutation = ({ workspaceName, documentId }) => {
	const logger = useLogger("useRenameDocumentMutation");

	const queryClient = useQueryClient();

	const { updateAsync: updateDocument } = useUpdateDocumentMutation({
		workspaceName,
		documentId,
	});
	const { updateAsync: updateWorkspace } = useUpdateWorkspaceMutation();

	return useMutation({
		mutationKey: documentQueryKeys.renameDocument,
		mutationFn: async (args: { workspaceName: string; documentId: string; title: string }) => {
			const { workspaceName, documentId, title } = args;

			return await Promise.all([
				// Update the document
				updateDocument({ workspaceName, documentId }, (document) => {
					if (!document) {
						logger.error("Document not found");
						return;
					}

					document.title = title;

					return document;
				}),

				// Update the workspace
				updateWorkspace({ workspaceName }, (workspace) => {
					if (!workspace) {
						logger.error("Workspace not found");
						return;
					}

					const document = workspace.documents[documentId];
					if (!document) {
						logger.error("Workspace document not found");
						return;
					}

					document.title = title;
					return workspace;
				}),
			]);
		},
		async onSuccess(_data, variables) {
			await Promise.all([
				queryClient.invalidateQueries({
					queryKey: workspaceQueryKeys.workspaces(FILE_SYSTEM_NAMESPACE, false),
				}),
				queryClient.invalidateQueries({
					queryKey: workspaceQueryKeys.workspaces("remote", true),
				}),
				queryClient.invalidateQueries({
					queryKey: workspaceQueryKeys.workspace(
						FILE_SYSTEM_NAMESPACE,
						variables.workspaceName
					),
				}),
				queryClient.invalidateQueries({
					queryKey: workspaceQueryKeys.workspace("remote", variables.workspaceName),
				}),
				queryClient.invalidateQueries({
					queryKey: documentQueryKeys.document(
						variables.workspaceName,
						variables.documentId
					),
				}),
			]);
		},
	});
};
