import { useRouter } from "next/router";
import { useMemo } from "react";

import { type LogFunc, Logger } from "~/utils/logging";

import { useCurrentDocument } from "./useCurrentDocument";
import { useEaselCurrentPage } from "./useEaselCurrentPage";

export type UseLogger = {
	debug: LogFunc;
	info: LogFunc;
	warn: LogFunc;
	error: LogFunc;
};

/**
 * Canvas Hook for use in React contexts that have access to the redux store
 *
 *
 * @param moduleName - string, name of the module to log

 * @returns UseLogger - object with debug, info, warn, and error methods
 */
export const useLogger = (moduleName: string): UseLogger => {
	const router = useRouter();
	const logger = useMemo(() => new Logger(moduleName), [moduleName]);

	const document = useCurrentDocument();
	const currentPage = useEaselCurrentPage();

	const userID = undefined; //useAppSelector(selectUserID);
	const orgID = undefined; //useAppSelector(selectOrgID);

	const defaultContent = useMemo(
		() => ({
			userID,
			orgID: orgID || "unknown",
			orgName: "unknown",
			canvasID: document?.id ?? "undefined",
			pageId: currentPage?.id ?? "undefined",
			url: router.asPath,
			source: "useLogger",
			moduleName,
		}),
		[currentPage?.id, document?.id, moduleName, orgID, router.asPath, userID]
	);

	const value = useMemo(
		(): UseLogger => ({
			debug: (message, content) => logger.debug(message, { ...defaultContent, ...content }),
			info: (message, content) => logger.info(message, { ...defaultContent, ...content }),
			warn: (message, content) => logger.warn(message, { ...defaultContent, ...content }),
			error: (message, content) => logger.error(message, { ...defaultContent, ...content }),
		}),
		[defaultContent, logger]
	);

	return value;
};
