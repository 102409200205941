import { z } from "zod";

import { DocumentListItemSchema } from "../../models/CanvasListingItem";

export const WorkspaceSchema = z.object({
	id: z.string(),
	name: z.string().default(""),
	documents: z.record(DocumentListItemSchema).default({}),
	schemaVersion: z.number().default(0),
});

export type Workspace = z.infer<typeof WorkspaceSchema>;
