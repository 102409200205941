import { useMutation } from "@tanstack/react-query";

import { trpc } from "~/utils/trpc";

import { useUpdateDocumentMutation } from "../documents";
import { useUpdateWorkspaceMutation } from "../workspaces";

export const useRenamePageMutation = ({ workspaceName, documentId }) => {
	const utils = trpc.useUtils();

	const { updateAsync: updateDocument } = useUpdateDocumentMutation({
		workspaceName,
		documentId,
	});
	const { updateAsync: updateWorkspace } = useUpdateWorkspaceMutation();

	const mutation = useMutation({
		mutationFn: async (args: {
			workspaceName: string;
			documentId: string;
			pageId: string;
			title: string;
		}) => {
			//
			// TODO: Targeted fix to a race condition, we will find a better (less slow) way of
			// solving it soon.
			//
			// The race generally occurs when you create a new document and then immediately reanme
			// the first page. Often the workspace or the document query will not have been updated
			// yet, and it will throw one of the errors below (usually one of the "Document not
			// found" errors). The VERY slow but probably safe way to fix this is to just refetch
			// the documentand workspace queries so the data is always up to date.
			//

			const { workspaceName, documentId, pageId, title } = args;
			let docHasOnePage = false;

			await updateDocument({ workspaceName, documentId }, (document) => {
				if (!document) throw new Error("Document not found");

				const page = document.pagesMetadata.find((page) => page.id === pageId);
				if (!page) throw new Error("Page not found");

				// Check if the document has only one page
				// Used below to determine if the document title needs to be updated
				if (document.pagesMetadata.length === 1) docHasOnePage = true;

				page.title = title;

				return document;
			});

			// If the document is untitled and the first page is renamed, set the document title to the page title
			if (!docHasOnePage) return;

			await updateWorkspace({ workspaceName }, (workspace) => {
				if (!workspace) throw new Error("Workspace not found");

				const workspaceDocument = workspace.documents[documentId];
				if (!workspaceDocument) throw new Error("Document not found");

				// Only rename the document if it's untitled
				if (workspaceDocument.title === "") {
					workspaceDocument.title = title;
					workspace.documents[documentId] = workspaceDocument;
				}

				return workspace;
			});
		},
		onSuccess: () => void utils.git.invalidate(),
	});

	return mutation;
};
