import { useMutation, useQueryClient } from "@tanstack/react-query";
import { useRouter } from "next/router";

import { makeToast } from "@moment/design-system/Toast";

import { useAuth } from "~/auth/useAuth";
import { trpc } from "~/utils/trpc";

import { documentQueryKeys } from "../documents";
import { pageQueryKeys } from "../pages";

export const usePullRepoMutation = () => {
	const queryClient = useQueryClient();
	const utils = trpc.useUtils();

	const { getAccessTokenSilently } = useAuth();
	const router = useRouter();

	const { mutateAsync: pull } = trpc.git.pull.useMutation({
		onSuccess: async (_, { workspaceName, documentId }) => {
			if (workspaceName === "local") return;

			void queryClient.invalidateQueries({
				queryKey: documentQueryKeys.document(workspaceName, documentId),
			});
			void queryClient.invalidateQueries({
				queryKey: pageQueryKeys.documentPages(workspaceName, documentId),
			});
		},
		onError: (error) => {
			makeToast({
				message: `Failed to pull repo: ${error.message}`,
				variant: "error",
			});
		},
		onSettled: () => {
			// Invalidate all the git queries
			void utils.git.invalidate();
		},
	});

	// Wrap the mutation so we can add the auth token
	const mutation = useMutation({
		mutationFn: async (args: { workspaceName: string; documentId: string }) => {
			const { workspaceName, documentId } = args;

			if (workspaceName === "local") return;

			const authToken = await getAccessTokenSilently(true, router.asPath);
			if (!authToken) return;

			return await pull({ workspaceName, documentId, authToken });
		},
	});

	return mutation;
};
