import { useMemo } from "react";

import { useDocumentQuery } from "~/data/documents/useDocumentQuery";

import { useEasel } from "../easel";

export const useCurrentDocument = () => {
	const easel = useEasel();

	const workspaceName = useMemo(() => easel.workspaceName, [easel]);
	const documentId = useMemo(() => easel.documentId, [easel]);

	const { data } = useDocumentQuery({
		workspaceName,
		documentId,
	});

	return data ?? null;
};
