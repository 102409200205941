import { useMutation, useQueryClient } from "@tanstack/react-query";
import { produce } from "immer";

import { useLogger } from "~/components/canvas/hooks/useLogger";

import { type Workspace, workspaceQueryKeys } from ".";
import { FILE_SYSTEM_NAMESPACE } from "./constants";

export const useUpdateWorkspaceMutation = () => {
	const logger = useLogger("useUpdateWorkspaceMutation");

	const queryClient = useQueryClient();

	const mutation = useMutation({
		mutationKey: workspaceQueryKeys.updateWorkspace,
		mutationFn: async (args: { workspaceName: string; workspace: Workspace }) => {
			const workspace = {
				...args.workspace,
				documents: Object.fromEntries(
					Object.entries(args.workspace.documents).map(([key, value]) => {
						return [key, value];
					})
				),
			};

			await window.desktopIpcApi?.writeLocalWorkspaceMetadata(
				args.workspaceName,
				workspace as unknown as Workspace
			);

			return workspace;
		},
	});

	return {
		...mutation,
		updateAsync: async (
			args: { workspaceName: string; checkFilesystem?: boolean },
			updaterFn: (workspace: Workspace | undefined) => Workspace | undefined
		) => {
			args.checkFilesystem = args.checkFilesystem ?? false;

			let workspace = queryClient.setQueryData<Workspace>(
				workspaceQueryKeys.workspace(FILE_SYSTEM_NAMESPACE, args.workspaceName),
				(draft) => produce(draft, updaterFn)
			);

			if (!workspace && !args.checkFilesystem) {
				logger.error(`Workspace not found for ${args.workspaceName}`);
				return;
			}
			const ipcWorkspace = await window.desktopIpcApi?.fetchLocalWorkspace(
				args.workspaceName
			);
			if (!ipcWorkspace) {
				logger.error("Failed to fetch local workspace", args);
				throw new Error(`Failed to fetch local workspace: ${args.workspaceName}`);
			}

			// If we find the workspace on disk, update the query
			queryClient.setQueryData<Workspace>(
				workspaceQueryKeys.workspace(FILE_SYSTEM_NAMESPACE, args.workspaceName),
				ipcWorkspace
			);

			workspace = queryClient.setQueryData<Workspace>(
				workspaceQueryKeys.workspace(FILE_SYSTEM_NAMESPACE, args.workspaceName),
				(draft) => produce(draft, updaterFn)
			);

			if (!workspace) {
				logger.error(`Workspace not found for ${args.workspaceName} after ipc call`);
				return;
			}

			return mutation.mutateAsync({
				...args,
				workspace,
			});
		},
	};
};
